import { Variables } from "../../0.global/globalvariables"
import Web3 from "web3";

import { shortenaddress, getcurrentaddress, getprovider } from "../../0.global/1.loginsystem/connectweb3";


export async function Contracts(network) {
    let provider = await getprovider();
    var web3 = new Web3(provider);
    let pnsRegistryContract = new web3.eth.Contract((await Variables(network)).pnsRegistryAbi, (await Variables(network)).pnsRegistryAddr);
    let pnsRegistrarContract = new web3.eth.Contract((await Variables(network)).pnsRegistrarAbi, (await Variables(network)).pnsRegistrarAddr);
    let pnsResolverContract = new web3.eth.Contract((await Variables(network)).pnsResolverAbi, (await Variables(network)).pnsResolverAddr);
    let pnsErc721Contract = new web3.eth.Contract((await Variables(network)).pnsErc721Abi, (await Variables(network)).pnsErc721Addr);
    let pnsPricesOracleContract = new web3.eth.Contract((await Variables(network)).pnsPricesOracleAbi, (await Variables(network)).pnsPricesOracleAddr);
    let pnsRegistrarEthContract = new web3.eth.Contract((await Variables(network)).pnsRegistrarEthAbi, (await Variables(network)).pnsRegistrarEthAddr);
    let pnsPricesOracleEthContract = new web3.eth.Contract((await Variables(network)).pnsPricesOracleEthAbi, (await Variables(network)).pnsPricesOracleEthAddr);
    let polygonEthContract = new web3.eth.Contract((await Variables(network)).polygonEthAbi, (await Variables(network)).polygonEthAddr);

    return { pnsRegistryContract, pnsRegistrarContract, pnsErc721Contract, polygonEthContract, pnsResolverContract, pnsPricesOracleContract, pnsRegistrarEthContract, pnsPricesOracleEthContract };
}

export async function getCloudProvider(network, chain, type) {
    let provider;
    let url;
    if (network == "eth") {
        if (chain == "main") {
            if (type == "full") {
                url = process.env.REACT_APP_CHAINSTACK_ETHEREUM_MAINNET_FULL_ARRAY;
            } else if (chain == "archive") {
                url = process.env.REACT_APP_CHAINSTACK_ETHEREUM_MAINNET_ARCHIVE_ARRAY;
            }
        } else if (chain == "test") {
            if (type == "full") {
                url = process.env.REACT_APP_CHAINSTACK_ETHEREUM_GOERLI_FULL_ARRAY;
            } else if (chain == "archive") {
                url = process.env.REACT_APP_CHAINSTACK_ETHEREUM_GOERLI_FULL_ARRAY;
            }
        }
    } else if (network == "pol") {
        if (chain == "main") {
            if (type == "full") {
                url = process.env.REACT_APP_CHAINSTACK_POLYGON_MAINNET_FULL_ARRAY;
            } else if (chain == "archive") {
                url = process.env.REACT_APP_CHAINSTACK_POLYGON_MAINNET_FULL_ARRAY;
            }
        } else if (chain == "test") {
            if (type == "full") {
                url = process.env.REACT_APP_CHAINSTACK_POLYGON_MUMBAI_FULL_ARRAY;
            } else if (chain == "archive") {
                url = process.env.REACT_APP_CHAINSTACK_POLYGON_MUMBAI_FULL_ARRAY;
            }
        }
    }
    let providerArray = JSON.parse(url).links;
    const random = Math.floor(Math.random() * providerArray.length);
    provider = providerArray[random];

    return provider;


}

export async function CloudContracts(network, chain, type) {
    let provider = await getCloudProvider(network, chain, type);
    var web3 = new Web3(provider);
    let pnsRegistryContract = new web3.eth.Contract((await Variables(chain)).pnsRegistryAbi, (await Variables(chain)).pnsRegistryAddr);
    let pnsRegistrarContract = new web3.eth.Contract((await Variables(chain)).pnsRegistrarAbi, (await Variables(chain)).pnsRegistrarAddr);
    let pnsResolverContract = new web3.eth.Contract((await Variables(chain)).pnsResolverAbi, (await Variables(chain)).pnsResolverAddr);
    let pnsErc721Contract = new web3.eth.Contract((await Variables(chain)).pnsErc721Abi, (await Variables(chain)).pnsErc721Addr);
    let pnsPricesOracleContract = new web3.eth.Contract((await Variables(chain)).pnsPricesOracleAbi, (await Variables(chain)).pnsPricesOracleAddr);
    let pnsRegistrarEthContract = new web3.eth.Contract((await Variables(chain)).pnsRegistrarEthAbi, (await Variables(chain)).pnsRegistrarEthAddr);
    let pnsPricesOracleEthContract = new web3.eth.Contract((await Variables(chain)).pnsPricesOracleEthAbi, (await Variables(chain)).pnsPricesOracleEthAddr);
    let polygonEthContract = new web3.eth.Contract((await Variables(chain)).polygonEthAbi, (await Variables(chain)).polygonEthAddr);

    return { pnsRegistryContract, pnsRegistrarContract, pnsErc721Contract, pnsResolverContract, pnsPricesOracleContract, polygonEthContract, pnsRegistrarEthContract, pnsPricesOracleEthContract };
}

