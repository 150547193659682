import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import WebsiteImg from "../../../../1.resources/3.images/images/website.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class Div6 extends React.Component {

    componentDidMount() {

    }





    render() {
        let css = {
            img: {
                width: "30px",
                heigth: "30px"
            },
            iconsP: {
                marginLeft: "7.5%",
                fontWeight: "bold",
                fontSize: "90%"
            },
            iconsDiv: {
                display: "flex",

            }
        }


        return (

            <div id="linksDiv" style={{ padding: "2%", background: "#1B1B21", display: "flex", justifyContent: "space-evenly" }}>
                <a href="https://twitter.com/poldomains" target="_blank" style={css.iconsDiv} className="onlyvertcenter">
                    <div className="onlyvertcenter">
                        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxIDYuNzMwNzdDMjAuMzI1IDcuMDc2OTIgMTkuNjUgNy4xOTIzMSAxOC44NjI1IDcuMzA3NjlDMTkuNjUgNi44NDYxNSAyMC4yMTI1IDYuMTUzODUgMjAuNDM3NSA1LjIzMDc3QzE5Ljc2MjUgNS42OTIzMSAxOC45NzUgNS45MjMwOCAxOC4wNzUgNi4xNTM4NUMxNy40IDUuNDYxNTQgMTYuMzg3NSA1IDE1LjM3NSA1QzEzLjAxMjUgNSAxMS4yMTI1IDcuMzA3NjkgMTEuNzc1IDkuNjE1MzhDOC43Mzc1IDkuNSA2LjAzNzUgOCA0LjEyNSA1LjY5MjMxQzMuMTEyNSA3LjQyMzA4IDMuNjc1IDkuNjE1MzggNS4yNSAxMC43NjkyQzQuNjg3NSAxMC43NjkyIDQuMTI1IDEwLjUzODUgMy41NjI1IDEwLjMwNzdDMy41NjI1IDEyLjAzODUgNC44IDEzLjY1MzggNi40ODc1IDE0LjExNTRDNS45MjUgMTQuMjMwOCA1LjM2MjUgMTQuMzQ2MiA0LjggMTQuMjMwOEM1LjI1IDE1LjczMDggNi42IDE2Ljg4NDYgOC4yODc1IDE2Ljg4NDZDNi45Mzc1IDE3LjkyMzEgNC45MTI1IDE4LjUgMyAxOC4yNjkyQzQuNjg3NSAxOS4zMDc3IDYuNiAyMCA4LjYyNSAyMEMxNS40ODc1IDIwIDE5LjMxMjUgMTQuMTE1NCAxOS4wODc1IDguNjkyMzFDMTkuODc1IDguMjMwNzcgMjAuNTUgNy41Mzg0NiAyMSA2LjczMDc3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==" style={css.img} />
                    </div>
                    <p style={css.iconsP}>Twitter</p>
                </a>
                <a href="https://opensea.io/collection/pol-name-service" target="_blank" style={css.iconsDiv} className="onlyvertcenter">
                    <div className="onlyvertcenter">
                        <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.png" style={css.img} />
                    </div>
                    <p style={css.iconsP}>Opensea</p>
                </a>
                <a href="https://opensea.io/collection/pol-name-service" target="_blank" style={css.iconsDiv} className="onlyvertcenter">
                    <div className="onlyvertcenter">
                        <img src="https://polygon.technology/_nuxt/img/github-white.2fb6ca6.svg" style={css.img} />
                    </div>
                    <p style={css.iconsP}>Github</p>
                </a>

            </div>

        );
    }
}

export default Div6;