import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import LogoImg2 from "../../../../1.resources/3.images/logo/pns3.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class Div1 extends React.Component {

    componentDidMount() {

    }





    render() {


        return (
            <div id="div1Div" style={{ padding: "5%", paddingLeft: "30%", paddingRight: "30%", marginTop: "2%" }}>
                <center>
                    <p id="div1TitleP" style={{ fontSize: "300%", fontWeight: "bold", color: "#000" }}>Your Web3 username</p>
                    <p id="div1SubtitleP" style={{ fontSize: "120%", fontWeight: "bold", color: "#949495" }}>No more sandboxed usernames. Own your username, store an avatar and other profile data, and use it across services.</p>

                    <div id="div1GraphicDiv" style={{ display: "flex", justifyContent: "center" }}>
                        <div id="div1GraphicDiv1" style={{ margin: "1%", background: "#fff", marginTop: "5%", justifyContent: "center", borderRadius: "50px", padding: "1%", paddingLeft: "2%", paddingRight: "2%" }} className="onlyvertcenter shadow">
                            <img id="div1GraphicImg1" src="https://img.seadn.io/files/92f9bf437c71306a92e76a8fa84e6ace.png?auto=format&fit=max&w=750" style={{ width: "50px", borderRadius: "50px" }} />
                            <p style={{ fontWeight: "bold", marginLeft: "10%" }}>john.pol</p>
                        </div>
                        <div id="div1GraphicDiv2" style={{ margin: "1%", background: "#fff", marginTop: "5%", justifyContent: "center", borderRadius: "50px", padding: "1%", paddingLeft: "2%", paddingRight: "2%" }} className="onlyvertcenter shadow">
                            <img id="div1GraphicImg2" src="https://img.seadn.io/files/6a1548ebda8527cdec690bc3b9c3a4b5.png?auto=format&fit=max&w=750" style={{ width: "50px", borderRadius: "50px" }} />
                            <p style={{ fontWeight: "bold", marginLeft: "10%" }}>emily.pol</p>
                        </div>
                        <div id="div1GraphicDiv3" style={{ margin: "1%", background: "#fff", marginTop: "5%", justifyContent: "center", borderRadius: "50px", padding: "1%", paddingLeft: "2%", paddingRight: "2%" }} className="onlyvertcenter shadow">
                            <img id="div1GraphicImg3" src="https://img.seadn.io/files/dd90f541847bb2722bfb606e52054510.png?auto=format&fit=max&w=750" style={{ width: "50px", borderRadius: "50px" }} />
                            <p style={{ fontWeight: "bold", marginLeft: "10%" }}>hugo.pol</p>
                        </div>
                    </div>
                </center>
            </div >

        );
    }
}

export default Div1;