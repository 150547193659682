import { getcurrentaddress, getprovider } from "../../0.global/1.loginsystem/connectweb3";
import { CloudContracts, Contracts } from "./initializeContracts";
import ReactDom from "react-dom";
import Web3 from "web3";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from "react";
import { maticToUsd } from "../../0.global/currencyConversion";
import RegisterAllModal from "../../../../2.partials/1.mainpages/app/names/partials/registerAllModal";

export async function cart() {
    let currentAddress = await getcurrentaddress();
    if (currentAddress != "null") {
        let paramsArray = await getCart();

        if (paramsArray.length > 0) {
            if (window.innerWidth < 767) {
                ReactDom.render(
                    <div><GetMappingCartMobile currentAddress={currentAddress} /></div>,
                    document.getElementById("mycart")
                )
            } else {
                ReactDom.render(
                    <div><GetMappingCart currentAddress={currentAddress} /></div>,
                    document.getElementById("mycart")
                )
            }

        } else {
            if (window.innerWidth < 767) {
                ReactDom.render(
                    <div style={{ fontSize: "120%", textAlign: "left" }}>
                        <div style={{ width: "100%", marginTop: "10%" }}>
                            <p style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Cart <span id="count"></span></p>
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#606060", marginTop: "2.5%" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}>{currentAddress}</span></p>

                            <div style={{ width: "100%", background: "#fff", borderRadius: "18px", padding: "5%", marginTop: "5%", height: "fit-content" }} className="shadow">
                                <p style={{ fontWeight: "bold", fontSize: "110%" }}>Cart Total</p>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                    <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Items</p>
                                    <p id="cartItems" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>0</p>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                    <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Total Cost</p>
                                    <p id="cartTotal" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>$0.00</p>
                                </div>



                            </div>

                            <div id="mycart" style={{ marginTop: "5%" }}>
                                <div style={{ paddingTop: "10%" }}>
                                    <center>
                                        <p style={{ fontWeight: "bold", color: "#949495", fontSize: "100%" }}>There are no domains in your cart.</p>
                                    </center>
                                </div>
                            </div>
                        </div>

                    </div>,
                    document.getElementById("mycart")
                )
            } else {
                ReactDom.render(
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "60%", margin: "1%" }}>
                            <p style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Cart <span id="count"></span></p>
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#606060" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}>{currentAddress}</span></p>
                            <div id="mycart" style={{ marginTop: "5%" }}>
                                <div style={{ paddingTop: "10%" }}>
                                    <center>
                                        <p style={{ fontWeight: "bold", color: "#949495", fontSize: "100%" }}>There are no domains in your cart.</p>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "40%", background: "#fff", borderRadius: "18px", padding: "2%", margin: "1%", height: "fit-content" }} className="shadow">
                            <p style={{ fontWeight: "bold", fontSize: "110%" }}>Cart Total</p>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Items</p>
                                <p id="cartItems" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>0</p>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Total Cost</p>
                                <p id="cartTotal" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>$0.00</p>
                            </div>



                        </div>
                    </div>,
                    document.getElementById("mycart")
                )
            }

        }
    } else {
        if (window.innerWidth < 767) {
            ReactDom.render(
                <div style={{ fontSize: "120%", textAlign: "left" }}>
                    <div style={{ width: "100%", marginTop: "10%" }}>
                        <p style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Cart <span id="count"></span></p>
                        <p style={{ fontSize: "80%", fontWeight: "bold", color: "#606060", marginTop: "2.5%" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}>{currentAddress}</span></p>

                        <div style={{ width: "100%", background: "#fff", borderRadius: "18px", padding: "5%", marginTop: "5%", height: "fit-content" }} className="shadow">
                            <p style={{ fontWeight: "bold", fontSize: "110%" }}>Cart Total</p>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Items</p>
                                <p id="cartItems" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>0</p>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                                <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Total Cost</p>
                                <p id="cartTotal" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>$0.00</p>
                            </div>



                        </div>

                        <div id="mycart" style={{ marginTop: "5%" }}>
                            <div style={{ paddingTop: "10%" }}>
                                <center>
                                    <p style={{ fontWeight: "bold", color: "#949495", fontSize: "100%" }}>There are no domains in your cart.</p>
                                </center>
                            </div>
                        </div>
                    </div>

                </div>,
                document.getElementById("mycart")
            )
        } else {
            ReactDom.render(
                <div style={{ display: "flex" }}>
                    <div style={{ width: "60%", margin: "1%" }}>
                        <p style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Cart <span id="count"></span></p>
                        <p style={{ fontSize: "80%", fontWeight: "bold", color: "#606060" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}>{currentAddress}</span></p>
                        <div id="mycart" style={{ marginTop: "5%" }}>
                            <div style={{ paddingTop: "10%" }}>
                                <center>
                                    <p style={{ fontWeight: "bold", color: "#949495", fontSize: "100%" }}>There are no domains in your cart.</p>
                                </center>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "40%", background: "#fff", borderRadius: "18px", padding: "2%", margin: "1%", height: "fit-content" }} className="shadow">
                        <p style={{ fontWeight: "bold", fontSize: "110%" }}>Cart Total</p>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Items</p>
                            <p id="cartItems" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>0</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Total Cost</p>
                            <p id="cartTotal" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>$0.00</p>
                        </div>



                    </div>
                </div>,
                document.getElementById("mycart")
            )
        }
    }
}

export async function setCartTotal() {
    let cart = JSON.parse(localStorage.getItem("cart"));
    document.getElementById("cartItems").innerHTML = cart.length;

    let costsPromise = []
    for (let i = 0; i < cart.length; i++) {
        costsPromise.push(getCost(cart[i].name));
    }
    let costs = await Promise.all(costsPromise);

    let total = 0
    for (let i = 0; i < cart.length; i++) {
        total = total + (costs[i] * cart[i].expiration);
    }
    document.getElementById("cartTotal").innerHTML = await maticToUsd(total);
}


export default function GetMappingCart({ currentAddress }) {

    const [cart, setCart] = useState([]);
    const [change, setChange] = useState(0);

    async function changeDuration(param, index) {
        console.log(param, index);
        if (param == "increase") {
            let newCart = [...cart];
            newCart[index].expiration = newCart[index].expiration + 1;
            localStorage.setItem('cart', JSON.stringify(newCart));
            setChange(change + 1);
        } else if (param == "decrease") {
            if (cart[index].expiration > 1) {
                let newCart = [...cart];
                newCart[index].expiration = newCart[index].expiration - 1;
                localStorage.setItem('cart', JSON.stringify(newCart));
                setChange(change + 1);
            }
        }
    }

    async function removeCart(name) {
        await removeFromCart(name);
        setChange(change + 1);
    }

    useEffect(() => {
        setCart(JSON.parse(localStorage.getItem('cart')));
        setCartTotal();
    }, [change]);

    return (
        <div>
            <div style={{ display: "flex" }}>
                <div style={{ width: "60%", margin: "1%" }}>
                    <p style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Cart <span id="count"></span></p>
                    <p style={{ fontSize: "80%", fontWeight: "bold", color: "#606060" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}>{currentAddress}</span></p>
                    <div id="mycart" style={{ marginTop: "5%" }}>
                        <Mapping paramsArray={cart} changeDuration={changeDuration} removeCart={removeCart} />

                    </div>
                </div>
                <div style={{ width: "40%", background: "#fff", borderRadius: "18px", padding: "2%", margin: "1%", height: "fit-content" }} className="shadow">
                    <p style={{ fontWeight: "bold", fontSize: "110%" }}>Cart Total</p>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Items</p>
                        <p id="cartItems" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>0</p>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Total Cost</p>
                        <p id="cartTotal" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>$0.00</p>
                    </div>

                    <RegisterAllModal change={change} />
                </div>
            </div>
        </div>
    )
}


export function GetMappingCartMobile({ currentAddress }) {

    const [cart, setCart] = useState([]);
    const [change, setChange] = useState(0);

    async function changeDuration(param, index) {
        console.log(param, index);
        if (param == "increase") {
            let newCart = [...cart];
            newCart[index].expiration = newCart[index].expiration + 1;
            localStorage.setItem('cart', JSON.stringify(newCart));
            setChange(change + 1);
        } else if (param == "decrease") {
            if (cart[index].expiration > 1) {
                let newCart = [...cart];
                newCart[index].expiration = newCart[index].expiration - 1;
                localStorage.setItem('cart', JSON.stringify(newCart));
                setChange(change + 1);
            }
        }
    }

    async function removeCart(name) {
        await removeFromCart(name);
        setChange(change + 1);
    }

    useEffect(() => {
        setCart(JSON.parse(localStorage.getItem('cart')));
        setCartTotal();
    }, [change]);

    return (
        <div>

            <div style={{ fontSize: "120%", textAlign: "left" }}>
                <div style={{ width: "100%", marginTop: "10%" }}>
                    <p style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Cart <span id="count"></span></p>
                    <p style={{ fontSize: "80%", fontWeight: "bold", color: "#606060", marginTop: "2.5%" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}>{currentAddress}</span></p>

                    <div style={{ width: "100%", background: "#fff", borderRadius: "18px", padding: "5%", marginTop: "5%", height: "fit-content" }} className="shadow">
                        <p style={{ fontWeight: "bold", fontSize: "110%" }}>Cart Total</p>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Items</p>
                            <p id="cartItems" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>0</p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Total Cost</p>
                            <p id="cartTotal" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}>$0.00</p>
                        </div>

                        <RegisterAllModal change={change} />


                    </div>

                    <div id="mycart" style={{ marginTop: "5%" }}>
                        <div style={{ paddingTop: "10%" }}>
                            <p style={{ fontWeight: "bold", fontSize: "110%" }}>Items</p>
                            <MappingMobile paramsArray={cart} changeDuration={changeDuration} removeCart={removeCart} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export function Mapping({ paramsArray, changeDuration, removeCart }) {
    const map = paramsArray.map((item, index) =>
        <div key={index} >
            <div style={{ padding: "2%", paddingLeft: "3%", paddingRight: "3%", justifyContent: "space-between", backgroundColor: "#fff", borderRadius: "18px", marginTop: "4%" }} className="onlyvertcenter shadow">
                <div style={{ width: "35%" }}>
                    <a href={"/app/name/" + item.name}>
                        <p style={{ fontWeight: "bold", color: "#000", fontSize: "100%" }}>{item.name + ".pol"}</p>
                        <p style={{ fontWeight: "bold", color: "#606060", fontSize: "80%" }}>{ }</p>
                    </a>
                </div>
                <div style={{ width: "65%", justifyContent: "space-between" }} className="onlyvertcenter">
                    <div>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration Period</p>
                        <div className="onlyvertcenter" style={{ justifyContent: "center", marginTop: "5%" }}>
                            <div style={{ border: "1px solid #949495", borderRadius: "50px", padding: "4%" }} className="vertcenter" onClick={() => changeDuration("decrease", index)}>
                                <FontAwesomeIcon icon={['fas', 'fa-minus']} style={{ color: "#949495", fontSize: "60%" }} />
                            </div>
                            <div style={{ whiteSpace: "nowrap", marginLeft: "10%", marginRight: "10%" }}>
                                <p style={{ color: "#000", fontWeight: "bold" }}><span id="duration">{item.expiration}</span> year</p>
                            </div>
                            <div style={{ border: "1px solid #949495", borderRadius: "50px", padding: "4%", background: "#7e1f80" }} className="vertcenter" onClick={() => changeDuration("increase", index)}>
                                <FontAwesomeIcon icon={['fas', 'fa-plus']} style={{ color: "#fff", fontSize: "60%" }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: "5%" }}>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration price</p>
                        <div style={{ justifyContent: "center", marginTop: "5%" }} className="onlyvertcenter">
                            <div style={{ whiteSpace: "nowrap" }}>
                                <CostP name={item.name} expiration={item.expiration} />
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#a9a9aa", borderRadius: "50%", width: "25px", height: "25px" }} className="vertcenter" onClick={() => removeCart(item.name)}>
                        <FontAwesomeIcon icon={['fas', 'fa-x']} style={{ color: "#fff", fontSize: "50%" }} />
                    </div>
                </div>
            </div>
        </div>
    );
    return map;
}


export function MappingMobile({ paramsArray, changeDuration, removeCart }) {
    const map = paramsArray.map((item, index) =>
        <div key={index} >
            <div style={{ padding: "5%", paddingLeft: "3%", paddingRight: "3%", justifyContent: "space-between", backgroundColor: "#fff", borderRadius: "18px", marginTop: "4%" }} className=" shadow">
                <div style={{ width: "100%" }}>
                    <a href={"/app/name/" + item.name}>
                        <p style={{ fontWeight: "bold", color: "#000", fontSize: "100%" }}>{item.name + ".pol"}</p>
                        <p style={{ fontWeight: "bold", color: "#606060", fontSize: "80%" }}>{ }</p>
                    </a>
                </div>
                <div style={{ width: "100%", marginTop: "2.5%", justifyContent: "space-between" }} className="onlyvertcenter">
                    <div>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration Period</p>
                        <div className="onlyvertcenter" style={{ justifyContent: "center", marginTop: "5%" }}>
                            <div style={{ border: "1px solid #949495", borderRadius: "50px", padding: "4%" }} className="vertcenter" onClick={() => changeDuration("decrease", index)}>
                                <FontAwesomeIcon icon={['fas', 'fa-minus']} style={{ color: "#949495", fontSize: "60%" }} />
                            </div>
                            <div style={{ whiteSpace: "nowrap", marginLeft: "10%", marginRight: "10%" }}>
                                <p style={{ color: "#000", fontWeight: "bold" }}><span id="duration">{item.expiration}</span> year</p>
                            </div>
                            <div style={{ border: "1px solid #949495", borderRadius: "50px", padding: "4%", background: "#7e1f80" }} className="vertcenter" onClick={() => changeDuration("increase", index)}>
                                <FontAwesomeIcon icon={['fas', 'fa-plus']} style={{ color: "#fff", fontSize: "60%" }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: "5%" }}>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration price</p>
                        <div style={{ justifyContent: "center", marginTop: "5%" }} className="onlyvertcenter">
                            <div style={{ whiteSpace: "nowrap" }}>
                                <CostP name={item.name} expiration={item.expiration} />
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#a9a9aa", borderRadius: "50%", width: "25px", height: "25px" }} className="vertcenter" onClick={() => removeCart(item.name)}>
                        <FontAwesomeIcon icon={['fas', 'fa-x']} style={{ color: "#fff", fontSize: "50%" }} />
                    </div>
                </div>
            </div>
        </div>
    );
    return map;
}

function CostP({ name, expiration }) {
    const [totalCost, setTotalCost] = useState(0);

    async function setCost(name, expiration) {
        setTotalCost(await maticToUsd((await getCost(name)) * expiration));
    }

    useEffect(() => {
        setCost(name, expiration);
    }, [name, expiration]);

    return (
        <p style={{ color: "#000", fontWeight: "bold" }}>{totalCost}</p>
    )
}


export async function getCost(name) {
    let cost = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleContract.methods.getMaticCost(name, "1").call();
    cost = cost / 1e18;
    return cost;
}



export async function addToCart(name, expiration) {
    try {
        console.log(name, expiration);
        let cart = await getCart();
        console.log(cart);

        if (cart.find(o => o.name === name.toLowerCase())) {
            console.log("exists");
            return true;
        } else {
            cart.push({
                name: name.toLowerCase(),
                expiration: expiration
            })
            localStorage.setItem('cart', JSON.stringify(cart));
            console.log("added");
            return true;
        }
    } catch (e) {
        return false;
    }
}

export async function checkInCart(name) {
    let cart = await getCart();

    if (cart.find(o => o.name === name.toLowerCase())) {
        return true;
    } else {
        return false;
    }
}

export async function removeFromCart(name) {
    let cart = await getCart();

    if (cart.find(o => o.name === name.toLowerCase())) {
        let obj = cart.find(o => o.name === name.toLowerCase());
        var index = cart.indexOf(obj);
        if (index > -1) {
            cart.splice(index, 1);
        }
        localStorage.setItem('cart', JSON.stringify(cart));
    } else {
        return false;
    }
}

export async function getCart() {
    let cart = localStorage.getItem("cart");
    if (cart == null) {
        cart = []
        localStorage.setItem('cart', JSON.stringify(cart));
        return cart;
    } else {
        cart = JSON.parse(cart);

        let validPromise = []
        for (let i = 0; i < cart.length; i++) {
            validPromise.push((await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.resolveName(cart[i].name).call());
        }
        let valid = await Promise.allSettled(validPromise);
        console.log(valid);
        let newCart = []
        for (let i = 0; i < cart.length; i++) {
            if (valid[i].status == "rejected") {
                newCart.push({
                    name: cart[i].name,
                    expiration: cart[i].expiration
                })
            }
        }
        localStorage.setItem('cart', JSON.stringify(newCart));
        return newCart;
    }
}

export async function setCartIndex() {
    let cart = await getCart();
    document.getElementById("cartIndex").innerHTML = cart.length;
}
