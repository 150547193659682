import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import LogoImg2 from "../../../../1.resources/3.images/logo/pns3.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class IndexHeader extends React.Component {

    componentDidMount() {

    }





    render() {
        let css = {
            links: {
                fontSize: "100%",
                color: "#fff",
                fontWeight: "bold",
                marginRight: "2%"
            },
            vl: {
                borderLeft: "1px solid #D9D9D9",
            }
        }

        return (
            <div style={{ padding: "2.5%", display: "flex", paddingLeft: "15%", paddingRight: "15%", justifyContent: "space-evenly" }}>
                <div id="indexLogo" style={{ width: "40%" }} className="onlyvertcenter" >
                    <img src={LogoImg2} style={{ width: "50px", height: "50px" }} onClick={() => window.location = "/app"} />
                    <p style={{ fontWeight: "1000", fontSize: "170%", color: "#fff" }}>PNS</p>
                </div>
                <div style={{ width: "60%", display: "flex", justifyContent: "right" }} className="onlyvertcenter">
                    {/* <a href="/" style={css.links}>About</a>
                    <a href="/" style={css.links}>Developers</a> */}
                    <a href="https://docs.pol.domains/" target="_blank" style={css.links}>Docs</a>

                    <a href="/app">
                        <button className="bluebutton" style={{ marginLeft: "5%", whiteSpace: "nowrap", fontSize: "90%", cursor: "pointer" }}>Go to App</button>
                    </a>
                </div>

            </div >

        );
    }
}

export default IndexHeader;