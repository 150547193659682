import { callMoralisApiReadContract } from "../../0.global/api/callMoralisApi";
import { CloudContracts, Contracts } from "./initializeContracts";
import { populateRegistered } from "./populateRegistered";
import { populateUnregistered } from "./populateUnregistered";
import { domainToUnicode } from "url";
import { ens_normalize, ens_tokenize } from '@adraffy/ens-normalize';
const utf8 = require('utf8');
const uts46 = require("idna-uts46-hx");
var namehash = require('eth-ens-namehash')

export async function search() {
    let urlArray = window.location.pathname.split("/");
    let name = (urlArray[3]).toLowerCase();
    console.log(urlArray)
    const decoder = new TextDecoder('utf-8');
    const encoder = new TextEncoder('utf-8');
    console.log(name);
    if (name.includes("%")) {
        name = toEmoji(name);
    }
    console.log(name);
    document.getElementById("name").innerHTML = name;
    document.getElementById("title").innerHTML = name + ".pol - PNS";

    //let value = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsErc721Contract.methods.allTokensOfOwner("0xF9Bc1efdf4683ffEE19F5fBa0d8E6aDB2e4fFd10", "1", "2").call();
    //console.log(value);
    //let value = await (await Contracts()).pnsRegistrarEthContract.methods.totalCostEth([["test123", "0xF9Bc1efdf4683ffEE19F5fBa0d8E6aDB2e4fFd10", "1"]]).call();
    //let round = parseFloat(value) + 100000000000000;
    //let gas = await (await Contracts()).pnsRegistrarEthContract.methods.pnsRegisterMinter([["test1234", "0xF9Bc1efdf4683ffEE19F5fBa0d8E6aDB2e4fFd10", "1"]]).send({ from: "0xF9Bc1efdf4683ffEE19F5fBa0d8E6aDB2e4fFd10", value: round });
    //console.log(gas);

    if (name.length < 3 || name.length > 15) {
        document.getElementById("invalidDiv").style.display = "block";
    } else {
        try {
            document.getElementById("tokenImage").src = process.env.REACT_APP_METADATA_URL + "/metadata/generateimage=" + name + ".pol?400";
            let tokenID = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.resolveNameToTokenId(name).call();
            console.log(tokenID);
            populateRegistered(tokenID);

        } catch (e) {
            document.getElementById("tokenImage").src = process.env.REACT_APP_METADATA_URL + "/metadata/generateimage=" + name + ".pol?400";
            populateUnregistered(name);
        }
    }


}

export function toEmoji(name) {
    console.log(name);
    let string = []
    let emoji = []

    let allStrings = []

    for (let i = 0; i < name.length; i++) {
        if (name[i] == "%" || name[i - 1] == "%" || name[i - 2] == "%") {
            allStrings.push(string.join(""));
            string = []
            emoji.push(name[i]);
            if (i == name.length - 1) {
                allStrings.push(parseEmoji(emoji.join("")));
            }
        } else {
            allStrings.push(parseEmoji(emoji.join("")));
            emoji = []
            string.push(name[i]);
            if (i == name.length - 1) {
                allStrings.push(string.join(""));
            }
        }
    }
    console.log(string);
    console.log(emoji);

    let all = []
    for (let i = 0; i < allStrings.length; i++) {
        if (allStrings[i] != "") {
            all.push(allStrings[i]);
        }
    }
    console.log(all);
    return all.join("");
}

function parseEmoji(name) {
    name = name.replaceAll("%", "");

    let bytes = [...name.matchAll(/[^ ]{1,2}/g)].map(a => parseInt('0x' + a[0]));
    return Buffer.from(bytes).toString();
}