import React from "react";
import AppIndex from "../2.partials/1.mainpages/app/index";
import Cart from "../2.partials/1.mainpages/app/names/cart";
import MyDomains from "../2.partials/1.mainpages/app/names/mydomains";
import AppNames from "../2.partials/1.mainpages/app/names/names";
import IndexMain from "../2.partials/1.mainpages/index/indexmain";
import MobileHeader from "../2.partials/1.mainpages/mobile/mobileHeader";

class Index extends React.Component {
    componentDidMount() {
    }



    render() {

        let mainarea;
        let url = window.location.pathname;
        let urlArray = url.split("/");
        console.log(urlArray)
        if (url === "/") {
            mainarea = <IndexMain />
        } else if (urlArray[1] == "app") {
            if (urlArray.length > 2) {
                if (urlArray[2] == "name") {
                    mainarea = <AppNames />
                } else if (urlArray[2] == "mydomains") {
                    mainarea = <MyDomains />
                } else if (urlArray[2] == "cart") {
                    mainarea = <Cart />
                } else {
                    mainarea = <AppIndex />
                }
            } else {
                mainarea = <AppIndex />
            }
        }

        return (
            <div >
                <div id="desktop">
                    {mainarea}
                </div>
                <div id="mobile">
                    <MobileHeader />
                </div>
            </div>

        );
    }
}

export default Index;