import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeCost, setCost } from "../../../../../1.resources/2.js/1.mainpages/app/populateUnregistered";
import { register } from "../../../../../1.resources/2.js/1.mainpages/app/register";
import RegisterModal from "./registerModal";


function Unregistered() {

    const [duration, setDuration] = useState(1);
    const [modal, setModal] = useState(<RegisterModal duration={duration} />);

    function changeDuration(param) {
        if (param == "increase") {
            let newDuration = duration + 1;
            setDuration(newDuration);
            changeCost(newDuration);
        } else if (param == "decrease") {
            if (duration > 1) {
                let newDuration = duration - 1;
                setDuration(newDuration);
                changeCost(newDuration);
            }
        }
    }

    useEffect(() => {
        setModal(<RegisterModal duration={duration} />);
    }, [duration]);

    return (
        <div id="UnregisteredDiv" style={{ backgroundColor: "#fff", borderRadius: "18px", padding: "2.5%", marginTop: "3%", display: "none" }} className="shadow">

            <div id="errorAscii" style={{ marginBottom: "1%", display: "none" }} className="onlyvertcenter">
                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#F4A522", fontSize: "90%" }} />
                <p style={{ fontSize: "90%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>ATTENTION: This name contains non-ASCII characters.</p>
            </div>



            <div id="register">
                <div id="errorRenewal" style={{}} className="onlyvertcenter">
                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#F4A522", fontSize: "90%" }} />
                    <p id="errorRenewalP" style={{ fontSize: "90%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>Increase registration period to avoid renewal every year</p>
                </div>
                <div id="registerDetails" style={{ marginTop: "2.5%", display: "flex", justifyContent: "left", alignItems: "baseline" }}>
                    <div id="registerDetailsPeriod">
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration Period</p>

                        <div id="registerDetailsPeriodSub" style={{ marginTop: "10%" }} className="onlyvertcenter">
                            <div id="registerPeriod1" style={{ border: "1px solid #949495", borderRadius: "50px", padding: "5%" }} className="vertcenter" onClick={() => changeDuration("decrease")}>
                                <FontAwesomeIcon icon={['fas', 'fa-minus']} style={{ color: "#949495", fontSize: "80%" }} />
                            </div>
                            <div style={{ whiteSpace: "nowrap", marginLeft: "20%", marginRight: "20%" }}>
                                <p style={{ color: "#000", fontWeight: "bold" }}><span id="duration">{duration}</span> year</p>
                            </div>
                            <div id="registerPeriod2" style={{ border: "1px solid #949495", borderRadius: "50px", padding: "5%", background: "#7e1f80" }} className="vertcenter" onClick={() => changeDuration("increase")}>
                                <FontAwesomeIcon icon={['fas', 'fa-plus']} style={{ color: "#fff", fontSize: "80%" }} />
                            </div>
                        </div>
                    </div>
                    <div id="connectIcon" style={{ marginLeft: "7.5%", marginRight: "5%" }}>
                        <FontAwesomeIcon icon={['fas', 'fa-link']} style={{ color: "#949495", fontSize: "80%" }} />
                    </div>
                    <div id="registerDetailsPay">
                        <span id="oneCost" style={{ display: "none" }}></span>
                        <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration price to pay</p>
                        <div id="registerDetailsPaySub" style={{ marginTop: "10%" }} className="onlyvertcenter">
                            <div style={{ whiteSpace: "nowrap" }}>
                                <p style={{ color: "#000", fontWeight: "bold" }}><span id="registrationCost">0.045</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ marginTop: "2.5%" }}>
                {modal}
            </div>
        </div>

    );

}

export default Unregistered;