import Web3 from "web3";
import { getprovider } from "../../0.global/1.loginsystem/connectweb3";
import { maticToUsd } from "../../0.global/currencyConversion";
import { Variables } from "../../0.global/globalvariables";
import { CloudContracts, Contracts } from "./initializeContracts";
import { isASCII } from "./populateRegistered";
import { toEmoji } from "./search";


export async function populateUnregistered(name) {
    document.getElementById("UnregisteredDiv").style.display = "block";
    document.getElementById("registerP").style.display = "block";

    setCost(name);

    if (isASCII(name) == false) {
        document.getElementById("errorAscii").style.display = "flex";
    }

}

export async function setCost(name) {
    //let name = document.getElementById("name").innerHTML;
    let cost = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleContract.methods.getMaticCost(name, "1").call();
    //let cost = 23.87;
    //let estimatedGasCost = await getGasCost(name, cost);
    cost = cost / 1e18;
    document.getElementById("oneCost").innerHTML = cost;
    document.getElementById("registrationCost").innerHTML = await maticToUsd(cost);
    //document.getElementById("estimatedGasCost").innerHTML = estimatedGasCost;
    //let totalCost = parseFloat(cost) + parseFloat(estimatedGasCost);
    //document.getElementById("totalCost").innerHTML = Math.round(totalCost * 100) / 100;
    //document.getElementById("totalCostUSD").innerHTML = await maticToUsd(totalCost);
}

export async function changeCost(duration) {
    let cost = parseFloat(document.getElementById("oneCost").innerHTML);
    // let estimatedGasCost = document.getElementById("estimatedGasCost").innerHTML
    document.getElementById("registrationCost").innerHTML = await maticToUsd(cost * duration);
    //document.getElementById("estimatedGasCost").innerHTML = estimatedGasCost;
    //let totalCost = parseFloat(cost * duration) + parseFloat(estimatedGasCost);
    //document.getElementById("totalCost").innerHTML = Math.round(totalCost * 100) / 100;
    //document.getElementById("totalCostUSD").innerHTML = await maticToUsd(totalCost);
}

async function getGasCost(name, cost) {
    let gasAmount = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsRegistrarContract.methods.pnsRegister([[name, "0xaf68E35CC60812878237b780841AA16cBaAa75aa", "1"]]).estimateGas({ value: cost });
    const provider = await getprovider();
    var web3 = new Web3(provider);
    let gasPrice = await web3.eth.getGasPrice();
    let gas = gasAmount * (gasPrice / 10e8);
    return parseFloat((gas / 10e8).toFixed(5))
}

