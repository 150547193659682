import $ from 'jquery';

export async function mobileAppCart() {
    if (window.innerWidth < 767) {
        let center1 = document.createElement("center");
        center1.id = "center1";
        $(center1).appendTo('#mobile');
        let br = document.createElement("br");

        /*  document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true)); */

        document.getElementById("mobileheader").style.display = "flex";
        $("#mycart").appendTo('#center1');
        document.getElementById("mycart").style.marginLeft = "5%";
        document.getElementById("mycart").style.marginRight = "5%";
    }
}

