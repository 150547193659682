import React from "react";
import AppHeader from "./header/appheader";
import UsernameImg from "../../../1.resources/3.images/images/username.png"
import { search } from "../../../1.resources/2.js/1.mainpages/app/search";
import { info } from "../../../1.resources/2.js/1.mainpages/app/app";
import EmptyUser from "../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloudContracts } from "../../../1.resources/2.js/1.mainpages/app/initializeContracts";
import { mobileAppHome } from "../../../1.resources/2.js/2.mobile/appHome";

class AppIndex extends React.Component {
    componentDidMount() {
        mobileAppHome();
        document.getElementById("bg").style.minHeight = (window.innerHeight * 1.5) + "px";

        document.getElementById("appsearch").addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                console.log("working...")
                window.location.href = "/app/name/" + (document.getElementById("appsearch").value).toLowerCase();
            }
        });
        info();

        document.getElementById("appsearch").addEventListener("input", async function () {
            document.getElementById("load").style.display = "block";
            document.getElementById("itemstatus1").style.display = "none";
            console.log("changed");
            document.getElementById("searchdropdown1").style.display = "block";
            let value = (document.getElementById("appsearch").value).toLowerCase();
            document.getElementById("itemname1").innerHTML = value;
            // document.getElementById("accountname").innerHTML = value;
            document.getElementById("itema1").href = "/app/name/" + value;

            let status;
            if (value.length < 3 || value.length > 15) {
                status = "Invalid";
            } else {
                try {
                    let tokenId = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.resolveNameToTokenId(value).call();
                    status = "Registered";
                } catch (e) {
                    status = "Available";
                }
            }
            if (status != "Invalid" && status != "Registered") {
                document.getElementById("itemstatus1").style.color = "#49B393";
            } else {
                document.getElementById("itemstatus1").style.color = "#606060";
            }
            document.getElementById("itemstatus1").innerHTML = status;
            document.getElementById("load1").style.display = "none";
            document.getElementById("itemstatus1").style.display = "block";
        })
    }



    render() {
        let css = {
            shadow: {
                boxShadow: "0px 0px 300px 100px rgba(243,238,247,1)",
                width: "50%"
            }
        }
        return (
            <div id="bg" >
                <AppHeader />

                <div id="appHomeDiv1" style={{ marginTop: "10%" }}>
                    <center>
                        <img id="appHomeImg1" src={UsernameImg} style={{ width: "30%" }} />
                        <div style={css.shadow}><p> </p></div>
                        <p id="appHomeSubtitle" style={{ width: "40%", fontWeight: "bold", color: "#949495", marginTop: "1%" }}>Your identity across web3, one name for all your crypto addresses, and your decentralised website.</p>
                        <div id="appHomeSearch" style={{ width: "40%", marginTop: "2%" }} className="vertcenter">

                            <div className="headerdropdown" style={{ marginLeft: "2.5%" }}>
                                <input id="appsearch" className="headersearchbar" type="text" placeholder="Search for a name.." name="search" autoComplete="off" />
                                <div id="searchdropdown1" className="headerdropdown-content" style={{ fontSize: "90%" }}>
                                    <div style={{ padding: "0%" }}>
                                        <p style={{ marginLeft: "2.5%", marginTop: "1%", fontWeight: "bold", fontSize: "80%" }}></p>
                                        <a id="itema1" href="" style={{ cursor: "pointer", display: "flex" }} className="onlyvertcenter">
                                            <div style={{ width: "70%" }} className="onlyvertcenter">
                                                <img src={EmptyUser} style={{ borderRadius: "50px", width: "30px" }} />
                                                <div style={{ marginLeft: "2.5%" }}>
                                                    <span id="itemname1" style={{ fontWeight: "bold" }}></span>
                                                    <span id="itemext1" style={{ fontWeight: "bold" }}>.pol</span>
                                                </div>
                                            </div>
                                            <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }} className="onlyvertcenter">
                                                <FontAwesomeIcon id="load1" icon={['fas', 'fa-circle-notch']} style={{ display: "none", color: "#7e1f80", fontSize: "90%" }} spin />

                                                <p id="itemstatus1" style={{ display: "none", color: "#49B393", fontSize: "80%", fontWeight: "bold", backgroundColor: "#F3F9F7", borderRadius: "50px", padding: "1%", paddingLeft: "5%", paddingRight: "5%" }}>Invalid</p>
                                                <FontAwesomeIcon id="load1" icon={['fas', 'fa-angle-right']} style={{ display: "block", color: "#606060", fontSize: "90%", fontWeight: "bold", marginLeft: "2.5%" }} />

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>

                </div>
            </div>

        );
    }
}

export default AppIndex;