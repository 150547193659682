import React, { useState } from "react";
import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import LogoImg2 from "../../../../1.resources/3.images/logo/pns3.png"
import ConnectWallet from "../../connectwallet/connectwallet";
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";
import { setCartIndex } from "../../../../1.resources/2.js/1.mainpages/app/cart";

class AppHeader extends React.Component {

    componentDidMount() {
        setCartIndex();
        document.getElementById("headersearch").addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                console.log("working...")
                window.location.href = "/app/name/" + (document.getElementById("headersearch").value).toLowerCase();
            }
        });

        document.getElementById("headersearch").addEventListener("input", async function () {
            document.getElementById("load").style.display = "block";
            document.getElementById("itemstatus").style.display = "none";
            console.log("changed");
            document.getElementById("searchdropdown").style.display = "block";
            let value = (document.getElementById("headersearch").value).toLowerCase();
            document.getElementById("itemname").innerHTML = value;
            // document.getElementById("accountname").innerHTML = value;
            document.getElementById("itema").href = "/app/name/" + value;

            let status;
            if (value.length < 3 || value.length > 15) {
                status = "Invalid";
            } else {
                try {
                    let tokenId = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.resolveNameToTokenId(value).call();
                    status = "Registered";
                } catch (e) {
                    status = "Available";
                }
            }
            if (status != "Invalid" && status != "Registered") {
                document.getElementById("itemstatus").style.color = "#49B393";
            } else {
                document.getElementById("itemstatus").style.color = "#606060";
            }
            document.getElementById("itemstatus").innerHTML = status;
            document.getElementById("load").style.display = "none";
            document.getElementById("itemstatus").style.display = "block";
        })
    }





    render() {


        let urlArray = window.location.pathname.split("/");
        let search = "none";
        let logoWidth = "22.5%";
        let logo = LogoImg;
        let lineHeight = "0%"
        if (urlArray.length > 2) {
            search = "block";
            logo = LogoImg2;
            logoWidth = "10%";
            lineHeight = "100%"
        }

        let css = {
            links: {
                fontSize: "100%",
                color: "#949495",
                fontWeight: "bold",
                marginRight: "2%"
            },
            vl: {
                borderLeft: "1px solid #D9D9D9",
                height: lineHeight
            }
        }
        return (
            <div style={{ padding: "2.5%", display: "flex", paddingRight: "5%" }}>
                <div style={{ width: "40%" }} className="onlyvertcenter" >
                    <img src={logo} style={{ width: logoWidth }} onClick={() => window.location = "/"} />
                    {/*                     <p style={{ fontWeight: "1000", color: "#000", fontSize: "170%" }}>PNS</p>
 */}                    <div style={css.vl}></div>
                    <div className="headerdropdown" style={{ marginLeft: "2.5%" }}>
                        <input id="headersearch" className="headersearchbar" type="text" placeholder="Search for a name.." name="search" style={{ display: search }} />
                        <div id="searchdropdown" className="headerdropdown-content" style={{ fontSize: "90%" }}>
                            <div style={{ padding: "0%" }}>
                                <p style={{ marginLeft: "2.5%", marginTop: "1%", fontWeight: "bold", fontSize: "80%" }}></p>
                                <a id="itema" href="" style={{ cursor: "pointer", display: "flex" }} className="onlyvertcenter">
                                    <div style={{ width: "70%" }} className="onlyvertcenter">
                                        <img src={EmptyUser} style={{ borderRadius: "50px", width: "30px" }} />
                                        <div style={{ marginLeft: "2.5%" }}>
                                            <span id="itemname" style={{ fontWeight: "bold" }}></span>
                                            <span id="itemext" style={{ fontWeight: "bold" }}>.pol</span>
                                        </div>
                                    </div>
                                    <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }} className="onlyvertcenter">
                                        <FontAwesomeIcon id="load" icon={['fas', 'fa-circle-notch']} style={{ display: "none", color: "#7e1f80", fontSize: "90%" }} spin />

                                        <p id="itemstatus" style={{ display: "none", color: "#49B393", fontSize: "80%", fontWeight: "bold", backgroundColor: "#F3F9F7", borderRadius: "50px", padding: "1%", paddingLeft: "5%", paddingRight: "5%" }}>Invalid</p>
                                        <FontAwesomeIcon id="load1" icon={['fas', 'fa-angle-right']} style={{ display: "block", color: "#606060", fontSize: "90%", fontWeight: "bold", marginLeft: "2.5%" }} />

                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "60%", display: "flex", justifyContent: "right" }} className="onlyvertcenter">
                    {/* <a href="/" style={css.links}>About</a>
                    <a href="/" style={css.links}>Developers</a> */}
                    <a href="https://docs.pol.domains/" target="_blank" style={css.links}>Docs</a>

                    <div id="headerRight" style={{ display: "flex", justifyContent: "right" }} className="onlyvertcenter">
                        <div className="wrapper" style={{ marginRight: "5%" }}>
                            <a href="/app/cart" style={{ background: "#fff", padding: "2%", borderRadius: "50px", width: "60px", height: "60px", justifyContent: "center" }} className="vertcenter shadow">
                                <FontAwesomeIcon icon={['fas', 'fa-bag-shopping']} style={{ color: "#949495", fontSize: "100%" }} />
                            </a>
                            <span id="cartIndex">0</span>
                        </div>


                        <div id="userDiv" className="dropdown">
                            <button id="dropdownbtn" className="dropdownbtn onlyvertcenter shadow" style={{ display: "flex", borderRadius: "50px", padding: "3.5%", paddingLeft: "20px", paddingRight: "40%" }}>
                                <img src={EmptyUser} style={{ borderRadius: "50px", width: "55px" }} />
                                <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                                    <p id="accAddressTitle" style={{ color: "#000", fontWeight: "bold", fontSize: "90%" }}></p>
                                    <p id="accAddressSubtitle" style={{ color: "#949495", fontWeight: "bold", fontSize: "80%", marginTop: "5%" }}></p>
                                </div>
                                <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ color: "#949495", fontSize: "80%" }} />
                            </button>
                            <div id="dropdown-content" className="dropdown-content menuaccount shadow">
                                <a href="/app/mydomains" style={{ cursor: "pointer" }}>My Domains</a>
                                <a style={{ cursor: "pointer", color: "#D55554" }} onClick={disconnectWallet}>Log out</a>
                            </div>

                        </div>
                        <ConnectWallet />
                    </div>
                </div>
            </div >

        );
    }
}

export default AppHeader;