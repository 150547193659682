import React, { useState, useEffect } from "react";
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from "react-modal";
import { callMoralisApi, callMoralisApiParams } from "../../../../../1.resources/2.js/0.global/api/callMoralisApi";
import { getcurrentaddress, getNetwork } from "../../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { ethToUsd, getGasPrice, maticToUsd, maticToUsdOnly, usdToEth } from "../../../../../1.resources/2.js/0.global/currencyConversion";
import EthLogo from "../../../../../1.resources/3.images/logo/eth.png";
import pEthLogo from "../../../../../1.resources/3.images/logo/pEth.png";
import MaticLogo from "../../../../../1.resources/3.images/logo/matic.png";
import { CloudContracts, Contracts, erc20Contract, getCloudProvider } from "../../../../../1.resources/2.js/1.mainpages/app/initializeContracts";
import Web3 from "web3";
import ConnectWallet from "../../../connectwallet/connectwallet";
import { approvePolygonEth, calculateValue, checkPolygonEthSpend, checkTransactionStatus, register, registerCart, registerErc20, registerErc20Cart, registerMinter, registerMinterCart } from "../../../../../1.resources/2.js/1.mainpages/app/register";
import { Variables } from "../../../../../1.resources/2.js/0.global/globalvariables";
import { addToCart, checkInCart, getCost, setCartIndex } from "../../../../../1.resources/2.js/1.mainpages/app/cart";

Modal.setAppElement("#root");

let css = {
    selected: {
        display: "flex",
        marginTop: "2%",
        justifyContent: "space-between",
        border: "2px solid #7e1f80",
        borderRadius: "18px",
        padding: "1%",
        paddingLeft: "2%",
        paddingRight: "2%"
    },
    unselected: {
        display: "flex",
        marginTop: "2%",
        justifyContent: "space-between",
        border: "2px solid #e5e5e5",
        borderRadius: "18px",
        padding: "1%",
        paddingLeft: "2%",
        paddingRight: "2%"
    }

}


export default function RegisterAllModal({ change }) {
    const [isOpen, setIsOpen] = useState(false);

    const options = [["ETH", "Ethereum", EthLogo, "ETH"],
    ["ETH", "Polygon", pEthLogo, "ETH"],
    ["MATIC", "Polygon", MaticLogo, "MATIC"]];

    const [walletConnected, setWalletConnected] = useState(false);

    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);
    const [balance, setBalance] = useState([0, 0, 0]);
    const [balanceCount, setBalanceCount] = useState(0);

    const [usdBalance, setUsdBalance] = useState([0, 0, 0]);
    const [total, setTotal] = useState(0);
    const [usdTotal, setUsdTotal] = useState(0);
    const [cost, setCost] = useState(0);
    const [errorNetwork, setErrorNetwork] = useState("none");
    const [errorBalance, setErrorBalance] = useState("none");
    const [networkP, setNetworkP] = useState("");
    const [gas, setGas] = useState([0, 0]);
    const [usdGas, setUsdGas] = useState([0, 0]);
    const [gasP, setGasP] = useState(["ETH", "MATIC", "MATIC"]);

    function toggling() {
        setIsSelectOpen(!isSelectOpen);

    }

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsSelectOpen(false);
        setTotalCost(value, balance, cost);
        setNetworkError(value);
    };

    async function setTotalCost(param, _balances, _cost) {
        console.log(_cost)
        let total = _cost * 1e18;
        setUsdTotal(await maticToUsd((total / 1e18)));
        if (param == 2) {
            total = total / 1e18;
            total = Math.round(((total) + Number.EPSILON) * 1000) / 1000;
            setTotal(parseFloat(total));
        } else {
            total = await maticToUsdOnly(total / 1e18);
            console.log(total);
            total = (await usdToEth(total));
            console.log(total);
            total = Math.round(((total) + Number.EPSILON) * 10000) / 10000;
            setTotal(parseFloat(total));
        }
        setBalanceError(total, param, _balances);
    }

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    async function setNetworkError(value) {
        let network = await getNetwork();
        console.log("network:" + network);
        let appNetwork = process.env.REACT_APP_NETWORK;
        let network1;
        let network2;
        let network1p;
        let network2p;
        if (appNetwork == "main") {
            network1 = 1;
            network2 = 137;
            network1p = "Ethereum Mainnet";
            network2p = "Polygon Mainnet";
        } else if (appNetwork == "test") {
            network1 = 5;
            network2 = 80001;
            network1p = "Ethereum (Goerli)";
            network2p = "Polygon (Mumbai)";
        }
        if (value == 0) {
            if (network != network1) {
                setErrorNetwork("flex");
                setNetworkP(network1p);
                return true;
            } else {
                setErrorNetwork("none");
                return false;
            }
        } else if (value == 1 || value == 2) {
            if (network != network2) {
                setErrorNetwork("flex");
                setNetworkP(network2p);
                return true;
            } else {
                setErrorNetwork("none");
                return false;
            }
        }
    }

    async function setBalanceError(_total, selected, _balances) {
        console.log("total:" + _total, "balance:" + _balances[selected])
        if (selected == 0) {
            if (_total > _balances[selected]) {
                setErrorBalance("flex");
                return true;
            } else {
                setErrorBalance("none");
                return false;
            }
        } else if (selected == 1) {
            console.log(_balances[2], gas[2]);
            if (_total > _balances[selected] || _balances[2] < gas[2]) {
                setErrorBalance("flex");
                return true;
            } else {
                setErrorBalance("none");
                return false;
            }
        } else if (selected == 2) {
            if (_total + gas[2] > _balances[selected]) {
                setErrorBalance("flex");
                return true;
            } else {
                setErrorBalance("none");
                return false;
            }
        }

    }

    async function getBalances() {
        setNetworkError(0);
        let chain1;
        let chain2;
        let network = process.env.REACT_APP_NETWORK;
        if (network == "main") {
            chain1 = "eth";
            chain2 = "polygon";
        } else {
            chain1 = "goerli";
            chain2 = "mumbai";
        }
        let currentAddress = await getcurrentaddress();

        let ethBalance = 0;
        let maticPolygonBalance = 0;
        let ethPolygonBalance = 0;

        if (currentAddress != "null") {
            setWalletConnected(true);
            var ethWeb3 = new Web3(await getCloudProvider("eth", network, "full"));
            var maticWeb3 = new Web3(await getCloudProvider("pol", network, "full"));

            ethBalance = await ethWeb3.eth.getBalance(currentAddress);
            maticPolygonBalance = await maticWeb3.eth.getBalance(currentAddress);
            ethPolygonBalance = await (await CloudContracts("pol", network, "full")).polygonEthContract.methods.balanceOf(currentAddress).call();

            ethBalance = parseFloat((parseFloat(ethBalance) / 1e18).toFixed(4));
            ethPolygonBalance = parseFloat((parseFloat(ethPolygonBalance) / 1e18).toFixed(4));
            maticPolygonBalance = parseFloat((parseFloat(maticPolygonBalance) / 1e18).toFixed(4));

            setBalance([ethBalance, ethPolygonBalance, maticPolygonBalance]);
            setUsdBalance([await ethToUsd(ethBalance), await ethToUsd(ethPolygonBalance), await maticToUsd(maticPolygonBalance)]);
        }
        let _cost = await getCartTotal();
        setCost(_cost);
        setTotalCost(0, [ethBalance, ethPolygonBalance, maticPolygonBalance], _cost);
        getGas();
        setBalanceCount(1);
    }

    async function getGas() {
        let gasPromise = [getMaticGas(), getEthGas()];
        let gasResolve = await Promise.all(gasPromise);
        console.log(gasResolve);
        setGas([gasResolve[1], gasResolve[0], gasResolve[0]]);
        setUsdGas([await ethToUsd(gasResolve[1]), await maticToUsd(gasResolve[0]), await maticToUsd(gasResolve[0])]);
        return [gasResolve[1], gasResolve[0], gasResolve[0]];
    }

    async function getMaticGas() {
        let cart = JSON.parse(localStorage.getItem("cart"));

        let tuple = []
        for (let i = 0; i < cart.length; i++) {
            tuple.push([cart[i].name, "0x17a5a07EEAB3588741C95E551470BFa945176367", cart[i].expiration]);
        }
        let _cost = (await getCartTotal()) * 1e18;
        console.log(_cost);
        let polGas = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsRegistrarContract.methods.pnsRegister(tuple).estimateGas({ value: calculateValue(_cost) });

        let gasPrice = await getGasPrice("pol");
        let gas = polGas * gasPrice;
        console.log("maticGas", polGas)
        return parseFloat((gas / 10e8).toFixed(5));
    }

    async function getEthGas() {
        let cart = JSON.parse(localStorage.getItem("cart"));

        let tuple = []
        for (let i = 0; i < cart.length; i++) {
            tuple.push([cart[i].name, "0x17a5a07EEAB3588741C95E551470BFa945176367", cart[i].expiration]);
        }
        let value = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsRegistrarEthContract.methods.totalCostEth(tuple).call();

        let ethGas = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsRegistrarEthContract.methods.pnsRegisterMinter(tuple).estimateGas({ value: calculateValue(value) });

        let gasPrice = await getGasPrice("eth");
        let gas = ethGas * gasPrice;
        console.log("ethGas:" + ethGas)
        return parseFloat((gas / 10e8).toFixed(5))
    }

    async function registerButton() {

        let registrant = await getcurrentaddress();

        let errorNetworkP = document.getElementById("errorNetwork").style.display;
        let errorBalanceP = document.getElementById("errorBalance").style.display;

        if (errorNetworkP == "none" && errorBalanceP == "none") {
            console.log("working...")
            let hash;

            document.getElementById("transaction").style.display = "block";
            document.getElementById("pendingDiv").style.display = "flex";
            document.getElementById("mainButton").style.display = "none";

            if (selectedOption == 0) {
                hash = await registerMinterCart();
                afterHash(hash);
            } else if (selectedOption == 1) {
                let currentAddress = await getcurrentaddress();
                let network = process.env.REACT_APP_NETWORK;
                let spend = await checkPolygonEthSpend(currentAddress, network);
                let cost = await getCartTotal();
                console.log("spend", parseFloat(spend), parseFloat(cost))

                if (parseFloat(cost) > parseFloat(spend)) {
                    document.getElementById("pendingP").innerHTML = "Approve the spend of ETH in your wallet. You only have to do this once."
                    document.getElementById("appearP").style.display = "none";
                    hash = await approvePolygonEth();

                    async function checkSpend() {
                        let newSpend = await checkPolygonEthSpend(currentAddress, network);
                        if (parseFloat(newSpend) < parseFloat(cost)) {
                            setTimeout(checkSpend, 3000);
                        } else {
                            document.getElementById("pendingP").innerHTML = "Transaction Pending"
                            document.getElementById("appearP").style.display = "block";
                            hash = await registerErc20Cart();
                            afterHash(hash);
                        }
                    }
                    await checkSpend();
                } else {
                    hash = await registerErc20Cart();
                    afterHash(hash);
                }

            } else if (selectedOption == 2) {
                hash = await registerCart()
                afterHash(hash);
            }


        }
    }

    async function afterHash(hash) {
        if (hash == "null") {
            document.getElementById("pendingDiv").style.display = "none";
            document.getElementById("transactionErrorDiv").style.display = "flex";
        } else {
            document.getElementById("pendingDiv").style.display = "none";
            document.getElementById("linkDiv").style.display = "flex";
            let link;
            let appNetwork = process.env.REACT_APP_NETWORK;
            if (selectedOption == 0) {
                if (appNetwork == "main") {
                    link = "https://etherscan.io/tx/" + hash;
                } else if (appNetwork == "test") {
                    link = "https://goerli.etherscan.io/tx/" + hash;
                }
            } else {
                if (appNetwork == "main") {
                    link = "https://polygonscan.com/tx/" + hash;
                } else if (appNetwork == "test") {
                    link = "https://mumbai.polygonscan.com/tx/" + hash;
                }
            }
            document.getElementById("linkDiv").href = link;
        }
    }



    useEffect(() => {
        getBalances();

    }, []);

    useEffect(() => {
        if (balanceCount != 0) {
            setTotalCost(selectedOption, balance, cost);
            getGas();
        }
    }, [change]);

    useEffect(() => {

    }, [walletConnected]);

    return (
        <div id="connectDiv" className="App" style={{ display: "block" }}>
            {walletConnected ?
                (
                    <div style={{ marginTop: "5%", display: "flex", justifyContent: "right" }}>
                        <button onClick={toggleModal} className="bluebutton" style={{ fontSize: "80%", whiteSpace: "nowrap", cursor: "pointer" }}>Register</button>
                    </div>
                )
                :
                (<ConnectWallet />)
            }

            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
            >


                <div style={{ padding: "2.5%", paddingLeft: "2.5%" }}>
                    <div>
                        <center>
                            <p style={{ fontSize: "120%", fontWeight: "bold" }}>Register</p>
                        </center>

                        <div style={{ marginTop: "4%" }}>
                            <p style={{ fontSize: "90%", fontWeight: "bold" }}>Select Payment Currency</p>

                            <div className="DropDownContainer" style={{ marginTop: "2%" }}>
                                <div className="DropDownHeader" onClick={toggling}>
                                    <div style={{ display: "flex", padding: "1.5%", paddingRight: "2%", paddingLeft: "2%", justifyContent: "space-between" }} className="onlyvertcenter">
                                        <div style={{ display: "flex", width: "50%" }} >
                                            <div style={{ width: "35px", height: "35px", padding: "2%", justifyItems: "center" }}>
                                                <img src={options[selectedOption][2]} style={{}} />
                                            </div>
                                            <div style={{ marginLeft: "4%" }}>
                                                <p style={{ fontWeight: "bold", fontSize: "80%", paddingLeft: "4%" }}>{options[selectedOption][0]}</p>
                                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#949495", paddingLeft: "4%" }}>{options[selectedOption][1]}</p>
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", justifyContent: "right" }} className="onlyvertcenter">
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#000" }}>Balance: {balance[selectedOption] + " " + options[selectedOption][3]}</p>
                                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#949495" }}>{usdBalance[selectedOption]}</p>
                                            </div>
                                            <div style={{ paddingLeft: "5%" }}>
                                                <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ color: "#949495", fontSize: "80%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isSelectOpen && (
                                    <div className="DropDownListContainer">
                                        <div className="DropDownList">
                                            {options.map((option, index) => (
                                                <div onClick={onOptionClicked(index)} key={index}>
                                                    <div style={{ display: "flex", padding: "1.5%", paddingRight: "2%", paddingLeft: "2%", justifyContent: "space-between" }} className="onlyvertcenter">
                                                        <div style={{ display: "flex", width: "50%" }} >
                                                            <div style={{ width: "35px", height: "35px", padding: "2%", justifyItems: "center" }}>
                                                                <img src={option[2]} style={{}} />
                                                            </div>
                                                            <div style={{ paddingLeft: "4%" }}>
                                                                <p style={{ fontWeight: "bold", fontSize: "80%", paddingLeft: "4%" }}>{option[0]}</p>
                                                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#949495", paddingLeft: "4%" }}>{option[1]}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "50%", justifyContent: "right" }} className="onlyvertcenter">
                                                            <div style={{ textAlign: "right" }}>
                                                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#000" }}>{balance[index] + " " + option[3]}</p>
                                                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#949495" }}>{usdBalance[index]}</p>
                                                            </div>
                                                            <div style={{ paddingLeft: "5%" }}>
                                                                <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ color: "#fff", fontSize: "80%" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div style={{ marginTop: "1%" }} className="onlyvertcenter">
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#949495", fontSize: "80%" }} />
                                <p style={{ fontSize: "80%", fontWeight: "bold", color: "#949495", marginLeft: "1%" }}>Regardless of payment currency, the domain will be minted on Polygon blockchain</p>
                            </div>

                        </div>

                        <div style={{ marginTop: "4%", justifyContent: "space-between" }} className="onlyvertcenter">
                            <div style={{ width: "50%" }}>
                                <p style={{ fontSize: "90%", fontWeight: "bold" }}>Total</p>
                            </div>
                            <div style={{ width: "50%", justifyContent: "right", textAlign: "right" }} >
                                <p style={{ fontSize: "90%", fontWeight: "bold", paddingLeft: "2%" }}>{total + " " + options[selectedOption][0]}</p>
                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#949495" }}>{usdTotal}</p>

                            </div>
                        </div>

                        <div style={{ marginTop: "4%", justifyContent: "space-between" }} className="onlyvertcenter">
                            <div >
                                <p style={{ fontSize: "90%", fontWeight: "bold" }}>Estimated Gas Fees</p>
                            </div>
                            <div style={{ width: "50%", justifyContent: "right", textAlign: "right" }}>
                                <p style={{ fontSize: "90%", fontWeight: "bold" }}>{gas[selectedOption] + " " + gasP[selectedOption]}</p>
                                <p style={{ fontWeight: "bold", fontSize: "80%", color: "#949495" }}>{usdGas[selectedOption]}</p>

                            </div>
                        </div>


                        <div style={{ marginTop: "4%", marginBottom: "4%" }}>
                            <div id="errorNetwork" style={{ marginBottom: "2%", display: errorNetwork }} className="onlyvertcenter">
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#F4A522", fontSize: "80%" }} />
                                <p style={{ fontSize: "80%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>Switch network to {networkP}</p>
                            </div>
                            <div id="errorBalance" style={{ marginBottom: "2%", display: errorBalance }} className="onlyvertcenter">
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "80%" }} />
                                <p style={{ fontSize: "80%", fontWeight: "bold", color: "#D55554", marginLeft: "1%" }}>Insufficient balance</p>
                            </div>
                            <div id="transaction" style={{ marginBottom: "6%", display: "none" }}>
                                <div id="pendingDiv" style={{ display: "none" }} className="onlyvertcenter">
                                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: "#F4A522", fontSize: "80%" }} spin />
                                    <p id="pendingP" style={{ fontSize: "80%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>Transaction pending</p>
                                </div>
                                <a id="linkDiv" target="_blank" style={{ display: "none", marginTop: "1%" }} className="onlyvertcenter">
                                    <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} style={{ color: "#7e1f80", fontSize: "80%" }} />
                                    <p style={{ fontSize: "80%", fontWeight: "bold", color: "#7e1f80", marginLeft: "1%" }}>View transaction on Block Explorer</p>
                                </a>
                                <div id="transactionErrorDiv" style={{ display: "none", marginTop: "1%" }} className="onlyvertcenter">
                                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "80%" }} />
                                    <p style={{ fontSize: "80%", fontWeight: "bold", color: "#D55554", marginLeft: "1%" }}>Something went wrong with the transaction</p>
                                </div>
                                <p id="appearP" style={{ fontSize: "80%", fontWeight: "bold", color: "#949495", marginTop: "1%" }}>The domain will appear in your wallet after this transaction is confirmed.</p>
                            </div>
                        </div>
                        <div id="mainButton">
                            <center>
                                <button className="bluebutton" style={{ fontSize: "80%", whiteSpace: "nowrap", cursor: "pointer" }} onClick={registerButton}>Continue</button>
                                <button className="graybutton" style={{ fontSize: "80%", whiteSpace: "nowrap", cursor: "pointer", marginLeft: "1%" }} onClick={toggleModal}>Cancel</button>
                            </center>
                        </div>
                    </div>


                </div>
            </Modal >
        </div >
    );
}



export async function getCartTotal() {
    let cart = JSON.parse(localStorage.getItem("cart"));

    let costsPromise = []
    for (let i = 0; i < cart.length; i++) {
        costsPromise.push(getCost(cart[i].name));
    }
    let costs = await Promise.all(costsPromise);

    let total = 0
    for (let i = 0; i < cart.length; i++) {
        total = total + (costs[i] * cart[i].expiration);
    }
    return total;
}
