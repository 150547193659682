import React, { useState } from "react";
import AppHeader from "../header/appheader";
import UsernameImg from "../../../../1.resources/3.images/images/username.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { populateName } from "../../../../1.resources/2.js/1.mainpages/app/populateUnregistered";
import SampleImg from "../../../../1.resources/3.images/images/sample.png"
import Unregistered from "./partials/unregistered";
import { info } from "../../../../1.resources/2.js/1.mainpages/app/app";
import Registered from "./partials/registered";
import { search } from "../../../../1.resources/2.js/1.mainpages/app/search";
import { cancelRecords, editRecords, saveRecords } from "../../../../1.resources/2.js/1.mainpages/app/populateRegistered";
import { cart } from "../../../../1.resources/2.js/1.mainpages/app/cart";
import RegisterAllModal from "./partials/registerAllModal";
import { mobileAppCart } from "../../../../1.resources/2.js/2.mobile/appCart";
class Cart extends React.Component {
    componentDidMount() {
        info();
        cart();
        mobileAppCart();
    }

    render() {

        return (
            <div>
                <AppHeader />

                <div id="mycart" style={{ marginTop: "0%", marginLeft: "15%", marginRight: "15%" }}>



                </div>
            </div>

        );
    }
}

export default Cart;