import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./3.views";
import "./2.partials/0.layout/imports_css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas, fab, far);

class App extends React.Component {
    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/app*" element={<Index />} />
                </Routes>
            </BrowserRouter>

        );
    }
}

export default App;