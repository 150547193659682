import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeCost, setCost } from "../../../../../1.resources/2.js/1.mainpages/app/populateUnregistered";
import { register } from "../../../../../1.resources/2.js/1.mainpages/app/register";
import RegisterModal from "./registerModal";


function Invalid() {

    return (
        <div id="invalidDiv" style={{ backgroundColor: "#fff", borderRadius: "18px", padding: "2.5%", marginTop: "6%", display: "none" }} className="shadow">

            <div id="errorLength" style={{ marginBottom: "1%" }} className="onlyvertcenter">
                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "90%" }} />
                <p style={{ fontSize: "90%", fontWeight: "bold", color: "#D55554", marginLeft: "1%" }}>Name is either too short or too long. Names must be between 3 and 15 characters long.</p>
            </div>


        </div>

    );

}

export default Invalid;