import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns3.png"
import WebsiteImg from "../../../../1.resources/3.images/images/website.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class Div5 extends React.Component {

    componentDidMount() {

    }





    render() {
        let css = {
            links: {
                color: "#fff",
                fontWeight: "bold",
                fontSize: "90%",
                marginLeft: "10%",
            }
        }


        return (

            <div id="footer" style={{ background: "#000", paddingLeft: "15%", paddingRight: "15%", paddingBottom: "2%" }}>
                <div id="footerDiv1" style={{ paddingTop: "5%", paddingBottom: "4%", display: "flex", justifyContent: "space-between", borderBottom: "2px solid #3B3B3D" }} className="onlyvertcenter">
                    <div>
                        <div id="footerLogo" style={{ display: "flex" }} className="onlyvertcenter">
                            <img src={LogoImg} style={{ width: "50px", height: "50px" }} />
                            <p style={{ fontWeight: "1000", color: "#fff", fontSize: "180%" }}>PNS</p>
                        </div>
                    </div>
                    <div id="footerDiv1Right" style={{ textAlign: "right", justifyContent: "right" }}>
                        <div id="footerDiv1RightLinks" style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                            {/* <a  style={css.links}>Home</a>
                            <a style={css.links}>Developers</a> */}
                            <a id="footerDiv1RightLink1" href="https://docs.pol.domains/" target="_blank" style={css.links}>Docs</a>
                        </div>
                        <div id="footerDiv1Email" style={{ float: "right" }}>
                            <p style={{ color: "#949495", fontWeight: "bold", fontSize: "80%", marginTop: "2%" }}>contact@pol.domains</p>
                        </div>
                    </div>
                </div>
                <center>
                    <p id="footerLove" style={{ color: "#fff", fontWeight: "bold", fontSize: "80%", marginTop: "2%" }}>Built on Polygon with 🤍</p>

                </center>

            </div>

        );
    }
}

export default Div5;