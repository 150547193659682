import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeCost, setCost } from "../../../../../1.resources/2.js/1.mainpages/app/populateUnregistered";
import { register } from "../../../../../1.resources/2.js/1.mainpages/app/register";

function Registered() {

    let css = {
        leftP: {
            fontWeight: "bold",
            color: "#656565",
            fontSize: "90%",
            marginBottom: "12%",
            textAlign: "left"
        },
        rightP: {
            fontWeight: "bold",
            color: "#949495",
            fontSize: "80%",
            marginBottom: "8%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left"
        }
    }

    return (
        <div id="RegisteredDiv" style={{ backgroundColor: "#fff", borderRadius: "18px", padding: "3.5%", marginTop: "3%", display: "none" }} className="shadow">

            <div id="registeredDivErrors">
                <div id="errorAscii2" style={{ marginBottom: "2%", display: "none" }} className="onlyvertcenter">
                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#F4A522", fontSize: "90%" }} />
                    <p style={{ fontSize: "90%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>ATTENTION: This name contains non-ASCII characters.</p>
                </div>

                <div id="networkError" style={{ marginBottom: "2%", display: "none" }} className="onlyvertcenter">
                    <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#F4A522", fontSize: "80%" }} />
                    <p id="networkErrorP" style={{ fontSize: "80%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>Switch network to Polygon Mainnet.</p>
                </div>
            </div>
            <div id="unregistered">

                <div id="addressesDiv" style={{ display: "flex" }}>
                    <div id="addressesDivTitle" style={{ width: "25%" }}>
                        <p style={{ fontWeight: "bold", color: "#000", fontSize: "90%" }}>Addresses</p>

                    </div>
                    <div id="addressesDivData" style={{ width: "75%" }}>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>ETH</p>
                            </div>
                            <div style={{ width: "65%" }} className="onlyvertcenter">
                                <p id="addresses-ETH" style={css.rightP}>not-set</p>
                                <input type="text" id="addresses-ETH-input" className="inputTxt" placeholder="Enter your address here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>BTC</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="addresses-BTC" style={css.rightP}>not-set</p>
                                <input type="text" id="addresses-BTC-input" className="inputTxt" placeholder="Enter your address here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>LTC</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="addresses-LTC" style={css.rightP}>not-set</p>
                                <input type="text" id="addresses-LTC-input" className="inputTxt" placeholder="Enter your address here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>DOGE</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="addresses-DOGE" style={css.rightP}>not-set</p>
                                <input type="text" id="addresses-DOGE-input" className="inputTxt" placeholder="Enter your address here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                    </div>

                </div>

                <div id="recordsDiv" style={{ display: "flex", marginTop: "4%" }}>
                    <div id="recordsDivTitle" style={{ width: "25%" }}>
                        <p style={{ fontWeight: "bold", color: "#000", fontSize: "90%" }}>Text Records</p>

                    </div>
                    <div id="recordsDivData" style={{ width: "75%" }}>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>avatar</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="avatar" style={css.rightP}>not-set</p>
                                <input type="text" id="avatar-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>email</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="email" style={css.rightP}>not-set</p>
                                <input type="text" id="email-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>url</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="url" style={css.rightP}>not-set</p>
                                <input type="text" id="url-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>description</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="description" style={css.rightP}>not-set</p>
                                <input type="text" id="description-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>com.discord</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="com.discord" style={css.rightP}>not-set</p>
                                <input type="text" id="com.discord-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>com.github</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="com.github" style={css.rightP}>not-set</p>
                                <input type="text" id="com.github-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>com.twitter</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="com.twitter" style={css.rightP}>not-set</p>
                                <input type="text" id="com.twitter-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                        <div style={{ display: "flex" }} className="onlyvertcenter">
                            <div style={{ width: "35%" }}>
                                <p style={css.leftP}>com.telegram</p>
                            </div>
                            <div style={{ width: "65%" }}>
                                <p id="com.telegram" style={css.rightP}>not-set</p>
                                <input type="text" id="com.telegram-input" className="inputTxt" placeholder="Enter your record here..." style={{ display: "none" }}></input>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

}

export default Registered;