import $ from 'jquery';

export async function mobileIndex() {
    if (window.innerWidth < 767) {
        let center1 = document.createElement("center");
        center1.id = "center1";
        $(center1).appendTo('#mobile');
        let br = document.createElement("br");

        /*  document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true)); */

        let hero = document.createElement("div");
        hero.style.paddingTop = "10%";
        hero.className = "bg";
        $("#indexLogo").appendTo(hero);
        $("#heroDiv").appendTo(hero);
        $(hero).appendTo(center1);
        document.getElementById("indexLogo").style.width = "100%";
        document.getElementById("indexLogo").style.paddingLeft = "10%";
        document.getElementById("indexLogo").style.justifyContent = "left";
        document.getElementById("heroTitleP").style.fontSize = "200%";
        document.getElementById("heroTitleP").style.paddingBottom = "20%";
        document.getElementById("heroTitleP").style.textAlign = "left";

        document.getElementById("heroSubtitleP").style.paddingBottom = "20%";
        document.getElementById("heroSubtitleP").style.textAlign = "left";
        document.getElementById("iconsDiv").style.justifyContent = "left";

        document.getElementById("heroInnerDiv").style.paddingLeft = "10%";
        document.getElementById("heroInnerDiv").style.paddingRight = "10%";
        document.getElementById("heroInnerDiv").style.paddingTop = "30%";
        document.getElementById("heroInnerDiv").style.paddingBottom = "30%";

        $("#div1Div").appendTo(center1);

        document.getElementById("div1Div").style.paddingLeft = "10%";
        document.getElementById("div1Div").style.paddingRight = "10%";
        document.getElementById("div1Div").style.paddingTop = "30%";
        document.getElementById("div1Div").style.paddingBottom = "30%";

        document.getElementById("div1TitleP").style.fontSize = "200%";
        document.getElementById("div1TitleP").style.textAlign = "left";
        document.getElementById("div1SubtitleP").style.textAlign = "left";
        document.getElementById("div1SubtitleP").style.paddingTop = "5%";

        document.getElementById("div1GraphicDiv").style.paddingTop = "10%";

        document.getElementById("div1GraphicDiv").style.display = "block";
        document.getElementById("div1GraphicDiv").style.justifyContent = "left";
        document.getElementById("div1GraphicDiv").style.textAlign = "left";

        document.getElementById("div1GraphicDiv1").style.paddingRight = "10%";
        document.getElementById("div1GraphicDiv2").style.paddingRight = "10%";
        document.getElementById("div1GraphicDiv3").style.paddingRight = "10%";

        document.getElementById("div1GraphicDiv1").style.fontSize = "120%";
        document.getElementById("div1GraphicDiv2").style.fontSize = "120%";
        document.getElementById("div1GraphicDiv3").style.fontSize = "120%";


        document.getElementById("div1GraphicDiv1").style.justifyContent = "left";
        document.getElementById("div1GraphicDiv2").style.justifyContent = "left";
        document.getElementById("div1GraphicDiv3").style.justifyContent = "left";

        document.getElementById("div1GraphicDiv1").style.width = "fit-content";
        document.getElementById("div1GraphicDiv2").style.width = "fit-content";
        document.getElementById("div1GraphicDiv3").style.width = "fit-content";

        $("#div2Div").appendTo(center1);

        document.getElementById("div2Div").style.paddingLeft = "10%";
        document.getElementById("div2Div").style.paddingRight = "10%";
        document.getElementById("div2Div").style.paddingTop = "0%";
        document.getElementById("div2Div").style.paddingBottom = "30%";

        document.getElementById("div2TitleP").style.fontSize = "200%";
        document.getElementById("div2TitleP").style.textAlign = "left";
        document.getElementById("div2SubtitleP").style.textAlign = "left";
        document.getElementById("div2SubtitleP").style.paddingTop = "5%";
        document.getElementById("div2SubtitleP").style.paddingLeft = "0%";
        document.getElementById("div2SubtitleP").style.paddingRight = "0%";

        document.getElementById("div2Img").style.width = "100%";
        document.getElementById("div2Img").style.paddingTop = "10%";


        $("#div3Div").appendTo(center1);

        document.getElementById("div3Div").style.paddingLeft = "10%";
        document.getElementById("div3Div").style.paddingRight = "10%";
        document.getElementById("div3Div").style.paddingTop = "0%";
        document.getElementById("div3Div").style.paddingBottom = "30%";

        document.getElementById("div3TitleP").style.fontSize = "200%";
        document.getElementById("div3TitleP").style.textAlign = "left";
        document.getElementById("div3SubtitleP").style.textAlign = "left";
        document.getElementById("div3SubtitleP").style.paddingTop = "5%";
        document.getElementById("div3SubtitleP").style.paddingLeft = "0%";
        document.getElementById("div3SubtitleP").style.paddingRight = "0%";

        document.getElementById("div3GraphicDiv").style.width = "100%";

        document.getElementById("div3Graphic1").style.paddingLeft = "0%";
        document.getElementById("div3GraphicDiv").style.paddingTop = "10%";


        $("#div4Div").appendTo(center1);

        document.getElementById("div4Div").style.paddingLeft = "10%";
        document.getElementById("div4Div").style.paddingRight = "10%";
        document.getElementById("div4Div").style.paddingTop = "0%";
        document.getElementById("div4Div").style.paddingBottom = "30%";

        document.getElementById("div4TitleP").style.fontSize = "200%";
        document.getElementById("div4TitleP").style.textAlign = "left";

        document.getElementById("div4GraphicDiv").style.marginLeft = "0%";
        document.getElementById("div4GraphicDiv").style.marginRight = "0%";
        document.getElementById("div4GraphicDiv").style.paddingTop = "10%";
        document.getElementById("div4GraphicDiv").style.paddingBottom = "10%";

        document.getElementById("div4GraphicDiv").style.gridTemplateColumns = "repeat(3, 1fr)";
        document.getElementById("div4GraphicDiv").style.gridRowGap = "40px";
        document.getElementById("div4GraphicDiv").style.marginTop = "10%";

        $("#linksDiv").appendTo(center1);

        document.getElementById("linksDiv").style.paddingTop = "5%";
        document.getElementById("linksDiv").style.paddingBottom = "5%";


        $("#footer").appendTo(center1);
        document.getElementById("footerLogo").style.justifyContent = "left";

        document.getElementById("footerDiv1").style.display = "block";
        document.getElementById("footerDiv1Right").style.justifyContent = "left";
        document.getElementById("footerDiv1Right").style.textAlign = "left";
        document.getElementById("footerDiv1RightLinks").style.justifyContent = "left";
        document.getElementById("footerDiv1RightLinks").style.textAlign = "left";

        document.getElementById("footerDiv1RightLink1").style.marginLeft = "0%";

        document.getElementById("footerDiv1Email").style.float = "none";
        document.getElementById("footerDiv1Email").style.justifyContent = "left";
        document.getElementById("footerDiv1Email").style.textAlign = "left";
        document.getElementById("footerDiv1Email").style.display = "flex";

        document.getElementById("footerLove").style.justifyContent = "left";
        document.getElementById("footerLove").style.textAlign = "left";
        document.getElementById("footerLove").style.display = "flex";
        document.getElementById("footerLove").style.paddingTop = "10%";
        document.getElementById("footerLove").style.paddingBottom = "10%";
        document.getElementById("footerLogo").style.paddingTop = "10%";
        document.getElementById("footerLogo").style.paddingBottom = "0%";
        document.getElementById("footerDiv1RightLinks").style.paddingTop = "5%";
        document.getElementById("footerDiv1RightLinks").style.paddingBottom = "5%";
        document.getElementById("footerDiv1Email").style.paddingBottom = "5%";

    }
}

