import { getcurrentaddress, getprovider } from "../../0.global/1.loginsystem/connectweb3";
import { CloudContracts, Contracts } from "./initializeContracts";
import ReactDom from "react-dom";
import Web3 from "web3";


export async function mydomains() {
    let currentAddress = await getcurrentaddress();
    if (currentAddress != "null") {
        document.getElementById("myaddress").innerHTML = currentAddress;

        let network = process.env.REACT_APP_NETWORK;
        let balance = await (await CloudContracts("pol", network, "full")).pnsErc721Contract.methods.balanceOf(currentAddress).call();
        console.log(balance);
        document.getElementById("count").innerHTML = `(${balance})`;

        if (balance > 0) {
            let tokens = await (await CloudContracts("pol", network, "full")).pnsResolverContract.methods.getAllTokensOfOwner(currentAddress, "1", balance).call();
            console.log(tokens);

            let items = []
            for (let i = 0; i < tokens[0].length; i++) {
                items.push({ name: tokens[1][i], expiration: tokens[2][i], tokenId: tokens[0][i] });
            }
            console.log(items);
            let map = await getMappingNames(items);
            ReactDom.render(
                <div>{map}</div>,
                document.getElementById("mydomains")
            )

        } else {
            ReactDom.render(
                <div style={{ paddingTop: "10%" }}>
                    <center>
                        <p style={{ fontWeight: "bold", color: "#949495", fontSize: "100%" }}>This account does not own any domains</p>
                    </center>
                </div>,
                document.getElementById("mydomains")
            )
        }
    } else {
        document.getElementById("myaddress").innerHTML = "No wallet connected";
        ReactDom.render(
            <div style={{ paddingTop: "10%" }}>
                <center>
                    <p style={{ fontWeight: "bold", color: "#949495", fontSize: "100%" }}>This account does not own any domains</p>
                </center>
            </div>,
            document.getElementById("mydomains")
        )
    }
}



async function getMappingNames(paramsArray, tokens) {
    let paddingDiv = "1.5%";
    let marginTopDiv = "2%";
    if (window.innerWidth < 767) {
        paddingDiv = "5%";
        marginTopDiv = "5%";
    }
    const map = paramsArray.map((item, index) =>
        <a key={index} href={"/app/name/" + item.name}>
            <div style={{ padding: paddingDiv, justifyContent: "space-between", backgroundColor: "#fff", borderRadius: "18px", marginTop: marginTopDiv }} className="onlyvertcenter shadow">
                <div style={{ width: "50%" }}>

                    {/*                     <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
 */}                    <div>
                        <p style={{ fontWeight: "bold", color: "#949495", fontSize: "80%" }}>{"Token ID: #" + item.tokenId}</p>
                        <p style={{ fontWeight: "bold", color: "#000", fontSize: "110%" }}>{item.name + ".pol"}</p>
                    </div>
                </div>
                <div className="onlyvertcenter" style={{ width: "50%", justifyContent: "right" }}>
                    <p style={{ fontWeight: "bold", color: "#949495" }}>expires on <span style={{ color: "#7e1f80" }}>{new Date(parseFloat(item.expiration) * 1000).toString().substring(4, 15)}</span></p>
                    {/*                     <button className="bluebutton" style={{ fontSize: "80%", marginLeft: "2%" }}>Renew</button>
 */}                </div>
            </div>
        </a >
    );

    return map;
}

