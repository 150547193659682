import $ from 'jquery';

export async function mobileAppNames() {
    if (window.innerWidth < 767) {
        let center1 = document.createElement("center");
        center1.id = "center1";
        $(center1).appendTo('#mobile');
        let br = document.createElement("br");

        /*  document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true)); */

        document.getElementById("mobileheader").style.display = "flex";
        $("#appNames").appendTo('#center1');
        document.getElementById("appNames").style.paddingTop = "5%";
        document.getElementById("appNames").style.marginLeft = "5%";
        document.getElementById("appNames").style.marginRight = "5%";

        document.getElementById("appNamesDiv1").style.display = "block";
        document.getElementById("appNamesLeft").style.width = "100%";
        document.getElementById("appNamesLeft").style.paddingBottom = "10%";
        document.getElementById("appNamesLeftDiv1").style.marginTop = "5%";

        document.getElementById("appNamesRight").style.width = "100%";
        document.getElementById("appNames").style.fontSize = "120%";
        document.getElementById("UnregisteredDiv").style.padding = "5%";
        document.getElementById("UnregisteredDiv").style.paddingTop = "10%";
        document.getElementById("UnregisteredDiv").style.paddingBottom = "10%";

        document.getElementById("errorRenewalP").style.textAlign = "left";
        document.getElementById("errorRenewalP").style.marginLeft = "5%";
        document.getElementById("registerDetails").style.display = "block";
        document.getElementById("registerDetailsPeriod").style.textAlign = "left";
        document.getElementById("registerDetailsPeriod").style.paddingTop = "10%";
        document.getElementById("registerPeriod1").style.padding = "2.5%";
        document.getElementById("registerPeriod2").style.padding = "2.5%";

        document.getElementById("connectIcon").style.display = "none";

        document.getElementById("registerDetailsPay").style.textAlign = "left";
        document.getElementById("registerDetailsPay").style.paddingTop = "15%";

        document.getElementById("registerButtons").style.textAlign = "left";
        document.getElementById("registerButtons").style.justifyContent = "left";
        document.getElementById("registerButtons").style.paddingTop = "10%";


        document.getElementById("appNames").style.paddingBottom = "20%";

        document.getElementById("registerDetailsPeriodSub").style.marginTop = "7.5%";
        document.getElementById("registerDetailsPaySub").style.marginTop = "5%";

        document.getElementById("addressesDiv").style.display = "block";
        document.getElementById("recordsDiv").style.display = "block";

        document.getElementById("addressesDivTitle").style.width = "100%";
        document.getElementById("addressesDivTitle").style.textAlign = "left";
        document.getElementById("addressesDivTitle").style.paddingBottom = "5%";

        document.getElementById("addressesDivData").style.width = "100%";

        document.getElementById("recordsDivTitle").style.width = "100%";
        document.getElementById("recordsDivTitle").style.textAlign = "left";
        document.getElementById("recordsDivTitle").style.paddingBottom = "5%";

        document.getElementById("recordsDivData").style.width = "100%";

        document.getElementById("RegisteredDiv").style.padding = "7.5%";

        document.getElementById("RegisteredDiv").style.paddingTop = "7.5%";
        document.getElementById("RegisteredDiv").style.paddingBottom = "7.5%";
        document.getElementById("registeredDivErrors").style.paddingBottom = "5%";

    }
}

