import { CloudContracts } from "../1.mainpages/app/initializeContracts";

function formatinusd(price) {
    price = price.toLocaleString('fullwide', { useGrouping: false });
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(price)
}

export async function ethToUsd(value) {
    console.log(value);
    /* let ethPrice;
    let tempEthPrice = sessionStorage.getItem("ethPrice");
    if (tempEthPrice != null) {
        ethPrice = tempEthPrice;
    } else { */
    let ethPrice = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleEthContract.methods.getEthPrice().call();
    /* sessionStorage.setItem("ethPrice", parseFloat(ethPrice));
} */
    /*  console.log(ethPrice / 1e8);
     let usdprice = (ethPrice * value;
     console.log(usdprice); */
    let usdprice = parseFloat(parseFloat((ethPrice / 1e8) * value));
    console.log(usdprice.toLocaleString('fullwide', { useGrouping: false }), formatinusd(usdprice))

    return formatinusd(usdprice);
}

export async function maticToUsd(value) {
    /* let ethPrice;
    let tempEthPrice = sessionStorage.getItem("maticPrice");
    if (tempEthPrice != null) {
        ethPrice = tempEthPrice;
    } else { */
    const ethPrice = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleContract.methods.getMaticPrice().call();

    /*  sessionStorage.setItem("maticPrice", ethPrice);
 } */
    let usdprice = parseFloat(parseFloat((ethPrice / 1e8) * value));

    return formatinusd(usdprice);
}

export async function maticToUsdOnly(value) {
    /* let ethPrice;
    let tempEthPrice = sessionStorage.getItem("maticPrice");
    if (tempEthPrice != null) {
        ethPrice = tempEthPrice;
    } else { */
    const ethPrice = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleContract.methods.getMaticPrice().call();
    /* const json = await result.json();
    ethPrice = json.USD;
    sessionStorage.setItem("maticPrice", ethPrice);
} */
    let usdprice = parseFloat(parseFloat((ethPrice / 1e8) * value).toFixed(2));
    return usdprice;
}

export async function usdToEth(value) {
    /* let ethPrice;
    let tempEthPrice = sessionStorage.getItem("ethPrice");
    if (tempEthPrice != null) {
        ethPrice = tempEthPrice;
    } else { */
    const ethPrice = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleEthContract.methods.getEthPrice().call();
    /* const json = await result.json();
    ethPrice = json.USD;
    sessionStorage.setItem("ethPrice", ethPrice);
} */
    let price = parseFloat(parseFloat((1 / (ethPrice / 1e8)) * value));
    return price;
}

export async function currentEthPrice() {
    /*  let ethPrice;
     let tempEthPrice = sessionStorage.getItem("ethPrice");
     if (tempEthPrice != null) {
         ethPrice = tempEthPrice;
     } else { */
    const ethPrice = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleEthContract.methods.getEthPrice().call();
    /* const json = await result.json();
    ethPrice = json.USD;
    sessionStorage.setItem("ethPrice", ethPrice);
} */
    return (ethPrice / 1e8);
}

export async function currentMaticPrice() {
    /* let ethPrice;
    let tempEthPrice = sessionStorage.getItem("maticPrice");
    if (tempEthPrice != null) {
        ethPrice = tempEthPrice;
    } else { */
    const ethPrice = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleContract.methods.getMaticPrice().call();
    /* const json = await result.json();
    ethPrice = json.USD;
    sessionStorage.setItem("maticPrice", ethPrice);
} */
    return (ethPrice / 1e8);
}

export async function getGasPrice(param) {
    if (param == "eth") {
        let result = await (await fetch("https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=Z59E9HRXDT7YW7MWB76I453B4KUDYQEGFE")).json();
        return result.result.ProposeGasPrice;
    } else if (param == "pol") {
        let result = await (await fetch("https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=K3GI78KY85F5UTPQYGPKXYADGNB9ZYYJJ9 ")).json();
        return result.result.ProposeGasPrice;
    }
}