import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import WebsiteImg from "../../../../1.resources/3.images/images/website.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class Div3 extends React.Component {

    componentDidMount() {

    }





    render() {


        return (

            <div id="div3Div" style={{ padding: "5%" }}>
                <div >
                    <center>
                        <p id="div3TitleP" style={{ fontSize: "300%", fontWeight: "bold", color: "#000" }}>Decentralised Websites</p>
                        <p id="div3SubtitleP" style={{ fontSize: "120%", fontWeight: "bold", color: "#949495", paddingLeft: "30%", paddingRight: "30%", marginTop: "1%" }}>Launch censorship-resistant decentralised websites with PNS. Upload your website to IPFS and access it with your PNS name.</p>
                    </center>
                </div >
                <center>
                    <div id="div3GraphicDiv" style={{ width: "50%", marginTop: "2%" }} className="onlyvertcenter">
                        <div id="div3Graphic1" style={{ padding: "5%" }}>
                            <img src={WebsiteImg} />
                            <p style={{ fontWeight: "bold", marginTop: "10%", color: "#000" }}>john.pol</p>
                        </div>
                        <div style={{ padding: "5%" }}>
                            <img src={WebsiteImg} />
                            <p style={{ fontWeight: "bold", marginTop: "10%" }}>emily.pol</p>

                        </div>
                        <div style={{ padding: "5%" }}>
                            <img src={WebsiteImg} />
                            <p style={{ fontWeight: "bold", marginTop: "10%" }}>223.pol</p>

                        </div>
                    </div>
                </center>
            </div>

        );
    }
}

export default Div3;