import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import Logos from "../../../../1.resources/3.images/images/logos.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class Div2 extends React.Component {

    componentDidMount() {

    }





    render() {
        let css = {
            div: {
                backgroundImage: "url('../../../../1.resources/3.images/images/logos.png')",
                padding: "20%"
            }
        }


        return (
            <div id="div2Div" style={{ padding: "5%" }}>
                <div >
                    <center>
                        <p id="div2TitleP" style={{ fontSize: "300%", fontWeight: "bold", color: "#000" }}>One name for all your addresses</p>
                        <p id="div2SubtitleP" style={{ fontSize: "120%", fontWeight: "bold", color: "#949495", paddingLeft: "30%", paddingRight: "30%", marginTop: "1%" }}>No more copying and pasting long addresses. Use your PNS name to store all of your addresses and receive any cryptocurrency, token, or NFT.</p>
                    </center>
                </div >
                <center>
                    <img id="div2Img" src={Logos} style={{ width: "50%", marginTop: "2.5%" }} />
                </center>
            </div>

        );
    }
}

export default Div2;