import React, { useState } from "react";
import "./styles.css";

import Modal from "react-modal";
import AssetsImg from "../../../1.resources/3.images/images/assets.png";
import LoginImg from "../../../1.resources/3.images/images/login.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connectMetamask, connectWalletConnect, walletConnect } from "../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";

Modal.setAppElement("#root");

export default function ConnectWallet() {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    let d = "flex";
    let d2 = "block";
    let w1 = "35%";
    let w2 = "65%";
    let m = "0%";
    if (window.innerWidth < 767) {
        d = "block";
        w1 = "100%";
        w2 = "100%";
        d2 = "none";
        m = "5%";
    }
    return (
        <div id="connectDiv" className="App" style={{ display: "block" }}>
            <button id="connectwalletbtn" onClick={toggleModal} className="bluebutton" style={{ whiteSpace: "nowrap", fontSize: "90%", cursor: "pointer" }}>Connect Wallet</button>

            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
            >


                <div style={{ paddingLeft: "2.5%" }}>

                    <div style={{ display: d, paddingTop: m, paddingBottom: m }}>
                        <div style={{ width: w1, paddingTop: "3.5%" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} className="onlyvertcenter">
                                <p style={{ fontSize: "110%", fontWeight: "bolder", marginLeft: "5%" }}>Connect a wallet</p>

                            </div>
                            <p style={{ fontSize: "80%", color: "#949495", fontWeight: "bold", marginTop: "10%", marginLeft: "5%" }}>Popular</p>

                            <div style={{ display: "flex", marginTop: "1%", marginRight: "5%" }} className="hover" onClick={connectMetamask}>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png" style={{ width: "30px", height: "30px" }} />
                                <p style={{ fontSize: "100%", fontWeight: "bold", marginLeft: "5%" }}>Metamask</p>
                            </div>
                            <div style={{ display: "flex", marginTop: "0%", marginRight: "5%" }} className="hover" onClick={walletConnect}>
                                <img src="https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png" style={{ width: "30px", height: "30px" }} />
                                <p style={{ fontSize: "100%", fontWeight: "bold", marginLeft: "5%" }}>WalletConnect</p>
                            </div>
                        </div>
                        <div style={{ width: w2, display: d2, backgroundColor: "#f8f8f8", paddingLeft: "7.5%", paddingTop: "3.5%" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingRight: "5%" }} className="onlyvertcenter">
                                <div></div>
                                <div style={{ cursor: "pointer" }} onClick={toggleModal}>
                                    <div style={{ backgroundColor: "#f0f0f0", borderRadius: "50%", width: "35px", height: "35px" }} className="vertcenter">
                                        <FontAwesomeIcon icon={['fas', 'fa-times']} style={{ color: "#606060", fontSize: "90%" }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: "5%", paddingBottom: "25%" }}>
                                <center>
                                    <p style={{ fontSize: "110%", fontWeight: "bold" }}>What is a wallet?</p>

                                </center>

                                <div style={{ display: "flex", marginTop: "10%" }} className="onlyvertcenter">
                                    <div>
                                        <img src={AssetsImg} style={{ width: "140px" }} />
                                    </div>
                                    <div style={{ paddingLeft: "5%", paddingRight: "15%" }}>
                                        <p style={{ fontWeight: "bold", fontSize: "80%" }}>A Home for your Digital Assets</p>
                                        <p style={{ fontSize: "80%", color: "#949495", fontWeight: "bold" }}>Wallets are used to send, receive, store, and display digital assets like Ethereum and NFTs.</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginTop: "10%" }} className="onlyvertcenter">
                                    <div>
                                        <img src={LoginImg} style={{ width: "140px" }} />
                                    </div>
                                    <div style={{ paddingLeft: "5%", paddingRight: "15%" }}>
                                        <p style={{ fontWeight: "bold", fontSize: "80%" }}>A New Way to Log In</p>
                                        <p style={{ fontSize: "80%", color: "#949495", fontWeight: "bold" }}>Instead of creating new accounts and passwords on every website, just connect your wallet.</p>
                                    </div>
                                </div>
                                <div style={{ marginTop: "10%" }}>
                                    <center>
                                        <a href="https://metamask.io/" target="_blank">
                                            <button className="bluebutton" style={{ fontSize: "80%" }}>Get a wallet</button>
                                        </a>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </Modal>
        </div>
    );
}
