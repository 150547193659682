import React, { useState, useEffect } from "react";
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from "react-modal";
import { callMoralisApi, callMoralisApiParams } from "../../../../../1.resources/2.js/0.global/api/callMoralisApi";
import { getcurrentaddress, getNetwork, getprovider } from "../../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { ethToUsd, getGasPrice, maticToUsd, maticToUsdOnly, usdToEth } from "../../../../../1.resources/2.js/0.global/currencyConversion";
import EthLogo from "../../../../../1.resources/3.images/logo/eth.png";
import pEthLogo from "../../../../../1.resources/3.images/logo/pEth.png";
import MaticLogo from "../../../../../1.resources/3.images/logo/matic.png";
import { CloudContracts, Contracts, erc20Contract, getCloudProvider } from "../../../../../1.resources/2.js/1.mainpages/app/initializeContracts";
import Web3 from "web3";
import ConnectWallet from "../../../connectwallet/connectwallet";
import { approvePolygonEth, checkPolygonEthSpend, checkTransactionStatus, register, registerErc20, registerMinter } from "../../../../../1.resources/2.js/1.mainpages/app/register";
import { Variables } from "../../../../../1.resources/2.js/0.global/globalvariables";

Modal.setAppElement("#root");

export default function TransferModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [renew, setRenew] = useState(false);
    const [appNetwork, setAppNetwork] = useState("Polygon Mainnet");
    const [appNetworkVisibility, setAppNetworkVisibility] = useState("none");
    const [address, setAddress] = useState("");
    const [buttonP, setButtonP] = useState("Transfer");
    const [duration, setDuration] = useState(1);
    const [maticCost, setMaticCost] = useState(0);
    const [costP, setCostP] = useState(0);
    const [usdCostP, setUsdCostP] = useState(0);
    const [cuurentExpiration, setCurrentExpiration] = useState(0);
    const [expirationP, setExpirationP] = useState(0);


    function toggleModal(param) {
        setIsOpen(!isOpen);
        if (param == "extend") {
            setRenew(true);
            setButtonP("Extend");
            setupExtent();
        } else if (param == "transfer") {
            setRenew(false);
            setButtonP("Transfer")
        }
    }

    async function setupExtent() {
        let name = document.getElementById("name").innerHTML;
        console.log(name);
        let cost = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleContract.methods.getMaticCost(name, "1").call();
        console.log(cost);
        setMaticCost(cost);
        setCostP(cost);
        setUsdCostP(await maticToUsd(cost / 1e18));
        let tokenId = document.getElementById("tokenID").innerHTML;
        tokenId = tokenId.substring(1);
        let expiration = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsRegistryContract.methods.getExpiration(tokenId).call();
        let newExpiration = parseFloat(expiration) + 31556952;
        setCurrentExpiration(expiration);
        setExpirationP(new Date(parseFloat(newExpiration) * 1000).toString().substring(4, 15))
    }

    async function checkAddress(val) {
        let value = val.target.value;
        console.log(value);
        let provider = await getprovider(process.env.REACT_APP_NETWORK);
        let web3 = new Web3(provider);
        if (value.includes(".pol")) {
            try {
                let name = value.substring(0, value.indexOf("."));
                console.log(name);
                let givenaddress = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.resolveName(name).call();
                console.log(givenaddress);
                let checksum = web3.utils.toChecksumAddress(givenaddress);
                console.log(checksum);
                document.getElementById("invalidDiv").style.display = "none";
                document.getElementById("validDiv").style.display = "flex";
                setAddress(checksum);
            } catch (e) {
                document.getElementById("invalidDiv").style.display = "flex";
                document.getElementById("validDiv").style.display = "none";
            }
        } else {
            try {
                let checksum = web3.utils.toChecksumAddress(value);
                console.log(checksum);
                document.getElementById("invalidDiv").style.display = "none";
                document.getElementById("validDiv").style.display = "flex";
                setAddress(checksum);
            } catch (e) {
                document.getElementById("invalidDiv").style.display = "flex";
                document.getElementById("validDiv").style.display = "none";
            }
        }

    }

    async function continueFunction() {
        let currentAddress = await getcurrentaddress();
        let tokenId = document.getElementById("tokenID").innerHTML;
        tokenId = tokenId.substring(1);
        document.getElementById("mainButton").style.display = "none";
        document.getElementById("pendingDiv").style.display = "flex";

        if (renew == false) {
            try {
                let gasPrice = await getGasPrice("pol");
                console.log(gasPrice);
                gasPrice = String(Math.round(gasPrice * 10e8));
                let tx = await (await Contracts(process.env.REACT_APP_NETWORK)).pnsErc721Contract.methods.safeTransferFrom(currentAddress, address, tokenId).send({ from: currentAddress, gasPrice: gasPrice });
                let hash = tx.transactionHash;
                document.getElementById("pendingDiv").style.display = "none";
                document.getElementById("linkDiv").style.display = "flex";
                document.getElementById("linkDiv").href = getLink(hash);

            } catch (e) {
                document.getElementById("pendingDiv").style.display = "none";
                document.getElementById("transactionErrorDiv").style.display = "flex";

            }
        } else {
            try {
                let value = ((parseFloat(maticCost) / 1e18) + 0.1) * 1e18;
                let gasPrice = await getGasPrice("pol");
                console.log(gasPrice);
                gasPrice = String(Math.round(gasPrice * 10e8));
                let tx = await (await Contracts(process.env.REACT_APP_NETWORK)).pnsRegistrarContract.methods.pnsRenewNames([[tokenId, duration]]).send({ value: value * duration, from: currentAddress, gasPrice: gasPrice });
                let hash = tx.transactionHash;
                document.getElementById("pendingDiv").style.display = "none";
                document.getElementById("linkDiv").style.display = "flex";

                document.getElementById("linkDiv").href = getLink(hash);
            } catch (e) {
                console.log(e);
                document.getElementById("pendingDiv").style.display = "none";
                document.getElementById("transactionErrorDiv").style.display = "flex";
            }
        }
    }


    function getLink(hash) {
        let link;
        let appNetwork = process.env.REACT_APP_NETWORK;
        if (appNetwork == "main") {
            link = "https://polygonscan.com/tx/" + hash;
        } else if (appNetwork == "test") {
            link = "https://mumbai.polygonscan.com/tx/" + hash;
        }
        return link;
    }
    async function network() {
        let network = await getNetwork();
        let appNetwork1 = process.env.REACT_APP_NETWORK;
        if (appNetwork1 == "test") {
            if (network == 80001) {
                setAppNetworkVisibility("none");
            } else {
                setAppNetwork("Polygon (Mumbai)")
                setAppNetworkVisibility("flex");
            }
        } else {
            if (network == 137) {
                setAppNetworkVisibility("none");
            } else {
                setAppNetwork("Polygon Mainnet")
                setAppNetworkVisibility("flex");
            }
        }
    }

    async function changeDuration(param) {
        if (param == "increase") {
            let newDuration = duration + 1;
            setDuration(newDuration);
            let newCost = maticCost * newDuration;
            setCostP(newCost);
            setUsdCostP(await maticToUsd(newCost / 1e18));
            let newExpiration = parseFloat(cuurentExpiration) + (31556952 * newDuration);
            setExpirationP(new Date(parseFloat(newExpiration) * 1000).toString().substring(4, 15))
        } else if (param == "decrease") {
            if (duration > 1) {
                let newDuration = duration - 1;
                setDuration(newDuration);
                let newCost = maticCost * newDuration;
                setCostP(newCost);
                setUsdCostP(await maticToUsd(newCost / 1e18));
                let newExpiration = parseFloat(cuurentExpiration) + (31556952 * newDuration);
                setExpirationP(new Date(parseFloat(newExpiration) * 1000).toString().substring(4, 15))
            }
        }
    }

    useEffect(() => {
        network();
    }, []);

    return (
        <div id="transferModal" className="App" style={{ display: "none" }}>
            <div className="onlyvertcenter" style={{ justifyContent: "center", marginTop: "4%" }}>
                <button id="transferButton" className="graybutton" style={{ fontSize: "80%", padding: "4%", paddingLeft: "8%", paddingRight: "8%", marginRight: "0%" }} onClick={() => toggleModal("transfer")}>Transfer</button>
                <button id="extendButton" className="graybutton" style={{ fontSize: "80%", padding: "4%", paddingLeft: "8%", paddingRight: "8%", marginLeft: "2%" }} onClick={() => toggleModal("extend")}>Extend</button>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
            >


                <div style={{ padding: "2.5%", paddingLeft: "2.5%" }}>
                    {renew ? (
                        <div>
                            <p style={{ fontSize: "120%", fontWeight: "bold" }}>Extend Registration</p>
                            <p style={{ fontSize: "90%", fontWeight: "bold", marginTop: "0%", color: "#949495" }}>Extend registration of the domain</p>

                            <div style={{ marginTop: "6%", display: "flex", justifyContent: "left", alignItems: "baseline" }}>
                                <div>
                                    <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration Period</p>

                                    <div style={{ marginTop: "10%" }} className="onlyvertcenter">
                                        <div style={{ border: "1px solid #949495", borderRadius: "50px", padding: "5%" }} className="vertcenter" onClick={() => changeDuration("decrease")}>
                                            <FontAwesomeIcon icon={['fas', 'fa-minus']} style={{ color: "#949495", fontSize: "80%" }} />
                                        </div>
                                        <div style={{ whiteSpace: "nowrap", marginLeft: "20%", marginRight: "20%" }}>
                                            <p style={{ color: "#000", fontWeight: "bold" }}><span id="duration">{duration}</span> year</p>
                                        </div>
                                        <div style={{ border: "1px solid #949495", borderRadius: "50px", padding: "5%", background: "#7e1f80" }} className="vertcenter" onClick={() => changeDuration("increase")}>
                                            <FontAwesomeIcon icon={['fas', 'fa-plus']} style={{ color: "#fff", fontSize: "80%" }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: "7.5%", marginRight: "5%" }}>
                                    <FontAwesomeIcon icon={['fas', 'fa-link']} style={{ color: "#949495", fontSize: "80%" }} />
                                </div>
                                <div>
                                    <span id="oneCost" style={{ display: "none" }}></span>
                                    <p style={{ color: "#949495", fontWeight: "bold", fontSize: "90%" }}>Registration price to pay</p>
                                    <div style={{ marginTop: "10%" }} className="onlyvertcenter">
                                        <div style={{ whiteSpace: "nowrap" }} className="onlyvertcenter">
                                            <p style={{ color: "#000", fontWeight: "bold" }}><span id="registrationCost">{parseFloat((costP / 1e18).toFixed(3)) + " MATIC"}</span></p>
                                            <p style={{ color: "#949495", fontWeight: "bold", marginLeft: "4%" }}><span id="registrationCost">({usdCostP})</span></p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p style={{ fontSize: "90%", fontWeight: "bold", color: "#949495", marginTop: "6%" }}>New expiration date: <span style={{ color: "#7e1f80" }}>{expirationP}</span></p>

                        </div>
                    ) : (
                        <div>
                            <p style={{ fontSize: "120%", fontWeight: "bold" }}>Transfer</p>
                            <p style={{ fontSize: "90%", fontWeight: "bold", marginTop: "0%", color: "#949495" }}>Transfer item to an Ethereum address</p>

                            <p style={{ fontSize: "90%", fontWeight: "bold", marginTop: "4%", marginBottom: "1%" }}>Address</p>
                            <input id="transferInput" type="text" className="inputTxt" placeholder="Enter an Ethereum address or PNS name..." onInput={(val) => checkAddress(val)}></input>


                        </div>
                    )}

                    <div style={{ marginBottom: "2%", marginTop: "2%" }}>
                        <div id="networkErrorDiv" style={{ display: appNetworkVisibility, marginTop: "1%" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#F4A522", fontSize: "80%" }} />
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>Switch network to {appNetwork}</p>
                        </div>
                        <div id="invalidDiv" style={{ display: "none", marginTop: "1%" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "80%" }} />
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#D55554", marginLeft: "1%" }}>Invalid address or PNS name</p>
                        </div>
                        <div id="validDiv" style={{ display: "none", marginTop: "1%" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-check']} style={{ color: "#7e1f80", fontSize: "80%" }} />
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#7e1f80", marginLeft: "1%" }}>This token will be sent to: {address}</p>
                        </div>
                        <div id="pendingDiv" style={{ display: "none" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: "#F4A522", fontSize: "80%" }} spin />
                            <p id="pendingP" style={{ fontSize: "80%", fontWeight: "bold", color: "#F4A522", marginLeft: "1%" }}>Transaction pending</p>
                        </div>
                        <a id="linkDiv" target="_blank" style={{ display: "none", marginTop: "1%" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-arrow-up-right-from-square']} style={{ color: "#7e1f80", fontSize: "80%" }} />
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#7e1f80", marginLeft: "1%" }}>View transaction on Block Explorer</p>
                        </a>
                        <div id="transactionErrorDiv" style={{ display: "none", marginTop: "1%" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "80%" }} />
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#D55554", marginLeft: "1%" }}>Something went wrong with the transaction</p>
                        </div>
                        <div id="errorBalance" style={{ marginBottom: "2%", display: "none" }} className="onlyvertcenter">
                            <FontAwesomeIcon icon={['fas', 'fa-info-circle']} style={{ color: "#D55554", fontSize: "80%" }} />
                            <p style={{ fontSize: "80%", fontWeight: "bold", color: "#D55554", marginLeft: "1%" }}>Insufficient balance</p>
                        </div>
                    </div>

                    <div id="mainButton" style={{ marginTop: "4%" }}>
                        <center>
                            <button className="bluebutton" style={{ fontSize: "80%", whiteSpace: "nowrap", cursor: "pointer" }} onClick={() => continueFunction()}>{buttonP}</button>
                            <button className="graybutton" style={{ fontSize: "80%", whiteSpace: "nowrap", cursor: "pointer", marginLeft: "1%" }} onClick={toggleModal}>Cancel</button>
                        </center>
                    </div>
                </div>
            </Modal >
        </div >
    );
}


