import $ from 'jquery';

import React, { useState } from "react";
import LogoImg2 from "../../../1.resources/3.images/logo/pns3.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class MobileHeader extends React.Component {

    componentDidMount() {
        if (window.innerWidth < 767) {
            $("#headerRight").appendTo('#mobileHeaderRight');
        }
    }





    render() {
        let css = {

            vl: {
                borderLeft: "2px solid #D9D9D9",
            }
        }

        return (
            <div>
                <div id="mobileheader" style={{ display: "none", padding: '5%', paddingLeft: "5%", paddingRight: "10%", borderBottom: '1px solid #e5e5e5', position: "sticky", zIndex: "100000000" }} className="mobileheader vertcenter">

                    <div style={{ width: "30%", textAlign: "left" }} className="onlyvertcenter">
                        <a href="/" id="mobheader" style={{}}  >
                            <img src={LogoImg2} style={{ width: "70px", height: "70px" }} onClick={() => window.location = "/app"} />
                        </a>
                    </div>
                    <div id="mobileHeaderRight" style={{ width: "70%", justifyContent: "right", display: "flex" }}>

                    </div>
                </div>
            </div >

        );
    }
}

export default MobileHeader;