import React from "react";
import Div1 from "./partials/1-web3";
import Div2 from "./partials/2-logos";
import Div3 from "./partials/3-website";
import IndexHero from "./partials/hero";
import IndexHeader from "./partials/indexHeader";
import "../../../1.resources/1.css/1.mainpages/index.css";
import Div4 from "./partials/4-apps";
import Div5 from "./partials/5-footer";
import Div6 from "./partials/6-links";
import { mobileIndex } from "../../../1.resources/2.js/2.mobile";
class IndexMain extends React.Component {
    componentDidMount() {
        document.getElementById("hero").style.height = (window.innerHeight * 1.25) + "px";
        mobileIndex();
    }



    render() {
        return (
            <div style={{}}>
                <div id="hero" className="bg" style={{}}>
                    <IndexHeader />
                    <IndexHero />
                </div>
                <Div1 />
                <Div2 />
                <Div3 />
                <Div4 />
                <Div6 />
                <Div5 />
            </div>

        );
    }
}

export default IndexMain;