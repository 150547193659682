import { getcurrentaddress, shortenaddress } from "../../0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "./initializeContracts";

export async function info() {
    let acc = await getcurrentaddress();
    console.log(acc);
    if (acc != "null") {
        document.getElementById("connectDiv").style.display = "none";
        document.getElementById("userDiv").style.display = "flex";
        try {
            let name = (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.resolveAddress(acc).call();
            console.log(name);
            document.getElementById("accAddressTitle").innerHTML = name;
            document.getElementById("accAddressSubtitle").innerHTML = shortenaddress(acc);
        } catch (e) {
            document.getElementById("accAddressTitle").innerHTML = shortenaddress(acc);

        }
    } else {
        document.getElementById("connectDiv").style.display = "block";
        document.getElementById("userDiv").style.display = "none";
    }

}