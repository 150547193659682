import { getcurrentaddress, getprovider } from "../../0.global/1.loginsystem/connectweb3";
import { Variables } from "../../0.global/globalvariables";
import { CloudContracts, Contracts, getCloudProvider } from "./initializeContracts";
import { BigNumber } from 'ethers';
import Web3 from "web3";
import { getCartTotal } from "../../../../2.partials/1.mainpages/app/names/partials/registerAllModal";
import { getGasPrice, maticToUsdOnly, usdToEth } from "../../0.global/currencyConversion";


export async function register(name, registrant, duration) {
    try {
        let network = process.env.REACT_APP_NETWORK;
        let currentAddress = await getcurrentaddress();

        let value = await (await Contracts(network)).pnsPricesOracleContract.methods.getMaticCost(name, duration).call();
        value = ((parseFloat(value) / 1e18) + 0.1) * 1e18;
        let gasPrice = await getGasPrice("pol");
        console.log(gasPrice);
        gasPrice = String(Math.ceil(gasPrice * 10e8));

        let tx = await (await Contracts(network)).pnsRegistrarContract.methods.pnsRegister([[name, registrant, duration]]).send({ from: currentAddress, value: calculateValue(value), gasPrice: gasPrice });
        return tx.transactionHash;
    } catch (e) {
        return "null";
    }
}

export async function registerCart() {
    try {
        let network = process.env.REACT_APP_NETWORK;
        let currentAddress = await getcurrentaddress();

        let cart = JSON.parse(localStorage.getItem("cart"));

        let tuple = []
        for (let i = 0; i < cart.length; i++) {
            tuple.push([cart[i].name, currentAddress, cart[i].expiration]);
        }
        let value = await getCartTotal();
        value = parseFloat(value) + 0.1;
        let gasPrice = await getGasPrice("pol");
        console.log(gasPrice);
        gasPrice = String(Math.ceil(gasPrice * 10e8));

        let tx = await (await Contracts(network)).pnsRegistrarContract.methods.pnsRegister(tuple).send({ from: currentAddress, value: calculateValue((parseFloat(value) * 1e18)), gasPrice: gasPrice });
        return tx.transactionHash;
    } catch (e) {
        console.log(e);
        return "null";
    }
}

export async function registerErc20(name, registrant, duration) {
    try {
        let network = process.env.REACT_APP_NETWORK;
        let currentAddress = await getcurrentaddress();

        let gasPrice = await getGasPrice("pol");
        console.log(gasPrice);
        gasPrice = String(Math.ceil(gasPrice * 10e8));

        let tx = await (await Contracts(network)).pnsRegistrarContract.methods.pnsRegisterWithErc20([[name, registrant, duration]]).send({ from: currentAddress, gasPrice: gasPrice });
        console.log(tx.hash);
        return tx.transactionHash;
    } catch (e) {
        return "null";
    }
}

export async function registerErc20Cart() {
    try {
        let network = process.env.REACT_APP_NETWORK;
        let currentAddress = await getcurrentaddress();

        let cart = JSON.parse(localStorage.getItem("cart"));

        let tuple = []
        for (let i = 0; i < cart.length; i++) {
            tuple.push([cart[i].name, currentAddress, cart[i].expiration]);
        }
        let gasPrice = await getGasPrice("pol");
        console.log(gasPrice);
        gasPrice = String(Math.ceil(gasPrice * 10e8));
        let tx = await (await Contracts(network)).pnsRegistrarContract.methods.pnsRegisterWithErc20(tuple).send({ from: currentAddress, gasPrice: gasPrice });
        console.log(tx.hash);
        return tx.transactionHash;
    } catch (e) {
        return "null";
    }
}

export async function registerMinter(name, registrant, duration) {
    /*  try { */
    let network = process.env.REACT_APP_NETWORK;
    let currentAddress = await getcurrentaddress();
    let value = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsPricesOracleEthContract.methods.getEthCost(name, duration).call();
    console.log(value);
    let tx = await (await Contracts(network)).pnsRegistrarEthContract.methods.pnsRegisterMinter([[name, currentAddress, duration]]).send({ from: currentAddress, value: calculateValue(parseFloat(value) + 1e14) });
    console.log("currentAddress");

    console.log(tx);
    return tx.transactionHash;
    /* } catch (e) {
        return "null";
    } */
}

export async function registerMinterCart(valIndex) {
    try {
        let network = process.env.REACT_APP_NETWORK;
        let currentAddress = await getcurrentaddress();

        let cart = JSON.parse(localStorage.getItem("cart"));

        let tuple = []
        for (let i = 0; i < cart.length; i++) {
            tuple.push([cart[i].name, currentAddress, cart[i].expiration]);
        }
        let value = await (await CloudContracts("eth", process.env.REACT_APP_NETWORK, "full")).pnsRegistrarEthContract.methods.totalCostEth(tuple).call();
        let tx = await (await Contracts(network)).pnsRegistrarEthContract.methods.pnsRegisterMinter(tuple).send({ from: currentAddress, value: calculateValue(value) });
        console.log(tx);
        return tx.transactionHash;

    } catch (e) {
        console.log(e);
        return "null";
    }
}

export async function approvePolygonEth() {
    /* try { */
    let network = process.env.REACT_APP_NETWORK;
    let currentAddress = await getcurrentaddress();
    console.log("approving..")
    let tx = await (await Contracts(network)).polygonEthContract.methods.approve((await Variables(network)).pnsRegistrarAddr, "115792089237316208592824440784624496904264408440904456176448000000").send({ from: currentAddress });
    //let tx = await (await Contracts(network)).polygonEthContract.methods.approve((await Variables(network)).pnsRegistrarAddr, "10").send({ from: currentAddress });

    console.log(tx);
    return tx.transactionHash;
    /* } catch (e) {
        return "null";
    } */
}


export async function checkTransactionStatus(hash, network, chain, type) {
    let provider = await getCloudProvider(network, chain, type);
    let web3 = new Web3(provider);
    let status = await web3.eth.getTransactionReceipt(hash);
    console.log(status);
}


export async function checkPolygonEthSpend(currentAddress, network) {
    let spend = await (await CloudContracts("pol", network, "full")).polygonEthContract.methods.allowance(currentAddress, (await Variables(network)).pnsRegistrarAddr).call();
    console.log(spend);
    return spend;
}


export async function transferToken(tokenID) {

}

export async function renewToken(tokenID) {

}

export function calculateValue(val) {
    val = parseFloat(val) / 1e18;
    let zeroes = getZeroes(val);
    console.log(zeroes);
    if (zeroes == 0) {
        val = Math.ceil(val * 100) / 100;
    } else if (zeroes == 1) {
        val = Math.ceil(val * 1000) / 1000;
    } else if (zeroes == 2) {
        val = Math.ceil(val * 10000) / 10000;
    } else if (zeroes == 3) {
        val = Math.ceil(val * 100000) / 100000;
    }
    console.log(val);
    return (val * 1e18).toLocaleString('fullwide', { useGrouping: false });
}

export function getZeroes(val) {
    return val.toString().match(/(\.0*)/)[0].length - 1;
}