import $ from 'jquery';

export async function mobileAppHome() {
    if (window.innerWidth < 767) {
        let center1 = document.createElement("center");
        center1.id = "center1";
        $(center1).appendTo('#mobile');
        let br = document.createElement("br");

        /*  document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true)); */

        document.getElementById("mobileheader").style.display = "flex";
        $("#appHomeDiv1").appendTo('#center1');
        document.getElementById("appHomeDiv1").style.padding = "10%";
        document.getElementById("appHomeImg1").style.width = "100%";
        document.getElementById("appHomeImg1").style.paddingTop = "10%";
        document.getElementById("appHomeImg1").style.paddingBottom = "2.5%";

        document.getElementById("appHomeSubtitle").style.width = "100%";
        document.getElementById("appHomeSubtitle").style.paddingBottom = "10%";

        document.getElementById("appHomeSearch").style.width = "100%";

    }
}

