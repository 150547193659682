import $ from 'jquery';

export async function mobileAppMyDomains() {
    if (window.innerWidth < 767) {
        let center1 = document.createElement("center");
        center1.id = "center1";
        $(center1).appendTo('#mobile');
        let br = document.createElement("br");

        /*  document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true));
         document.getElementById("center1").appendChild(br.cloneNode(true)); */

        document.getElementById("mobileheader").style.display = "flex";
        $("#mydomainsDiv").appendTo('#center1');
        document.getElementById("mydomainsDiv").style.textAlign = "left";
        document.getElementById("mydomainsDiv").style.marginLeft = "7.5%";
        document.getElementById("mydomainsDiv").style.marginRight = "7.5%";
        document.getElementById("mydomainsDiv").style.marginTop = "7.5%";
        document.getElementById("mydomainsDiv").style.fontSize = "120%";
        document.getElementById("mydomainsTitle").style.paddingBottom = "2.5%";
        document.getElementById("mydomainsSubtitle").style.paddingBottom = "2.5%";

    }
}

