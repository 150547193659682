import React, { useState } from "react";
import AppHeader from "../header/appheader";
import UsernameImg from "../../../../1.resources/3.images/images/username.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { populateName } from "../../../../1.resources/2.js/1.mainpages/app/populateUnregistered";
import SampleImg from "../../../../1.resources/3.images/images/sample.png"
import Unregistered from "./partials/unregistered";
import { info } from "../../../../1.resources/2.js/1.mainpages/app/app";
import Registered from "./partials/registered";
import { search } from "../../../../1.resources/2.js/1.mainpages/app/search";
import { cancelRecords, editRecords, saveRecords } from "../../../../1.resources/2.js/1.mainpages/app/populateRegistered";
import TransferModal from "./partials/transferModal";
import Invalid from "./partials/invalid";
import { mobileAppNames } from "../../../../1.resources/2.js/2.mobile/appNames";
class AppNames extends React.Component {
    componentDidMount() {
        mobileAppNames();
        search();
        info();
    }

    render() {
        let css = {
            shadow: {
                boxShadow: "0px 0px 300px 100px rgba(243,238,247,0.75)",
                width: "50%"
            },
            leftInfo: {
                fontWeight: "bold",
                color: "#949495",
                fontSize: "90%",
                whiteSpace: "nowrap"
            },
            rightInfo: {
                fontWeight: "bold",
                display: "flex",
                justifyContent: "right",
                width: "100%",
                fontSize: "90%"

            }
        }
        return (
            <div>
                <AppHeader />

                <div id="appNames" style={{ marginTop: "0%", marginLeft: "15%", marginRight: "15%" }}>
                    <div id="appNamesDiv1" style={{ display: "flex" }}>
                        <div id="appNamesLeft" style={{ width: "25%", padding: "1%" }}>
                            <div id="appNamesBack" style={{ width: "100%", display: "flex", alignItems: "center", paddingLeft: "5%", paddingRight: "5%" }}>
                                <a href="/app">
                                    <FontAwesomeIcon icon={['fas', 'fa-arrow-left']} style={{ color: "#949495", fontSize: "100%" }} />
                                </a>
                                <div style={css.leftInfo}>
                                    <div style={{ display: "flex" }}>
                                        <p id="name" style={{ color: "#000", fontSize: "120%", fontWeight: "bold", marginLeft: "15%" }}></p>
                                        <p id="ext" style={{ color: "#000", fontSize: "120%", fontWeight: "bold" }}>.pol</p>
                                    </div>

                                </div>
                            </div>
                            <div id="appNamesLeftDiv1" style={{ marginTop: "10%" }}>
                                <img id="tokenImage" src={SampleImg} style={{ width: "100%", borderRadius: "18px" }} className="shadow" />

                                <div id="tokenDetails" style={{ borderRadius: "18px", backgroundColor: "#fff", padding: "6.5%", marginTop: "5%" }} className="shadow">
                                    <div style={{ width: "100%" }} className="onlyvertcenter">
                                        <p style={css.leftInfo}>Expires</p>
                                        <p id="expiration" style={css.rightInfo}>Not registered</p>
                                    </div>
                                    <div style={{ width: "100%", marginTop: "2.5%" }} className="onlyvertcenter">
                                        <p style={css.leftInfo}>Controller</p>
                                        <p id="owner" style={css.rightInfo}>Not set</p>
                                    </div>
                                    <div style={{ width: "100%", marginTop: "2.5%" }} className="onlyvertcenter">
                                        <p style={css.leftInfo}>Token ID</p>
                                        <p id="tokenID" style={css.rightInfo}>Not set</p>
                                    </div>
                                    <TransferModal />

                                </div>
                            </div>
                        </div>
                        <div id="appNamesRight" style={{ width: "75%", padding: "1%" }}>
                            <div style={{ paddingLeft: "1%", paddingRight: "1%", justifyContent: "space-between" }} className="onlyvertcenter">
                                <div style={{ display: "flex" }}>
                                    <p id="registerP" style={{ fontSize: "120%", fontWeight: "bold", color: "#7e1f80", display: "none" }}>Register</p>
                                    <p id="detailsP" style={{ fontSize: "120%", fontWeight: "bold", color: "#7e1f80", display: "none" }}>Details</p>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <button id="editButton" className="bluebutton" style={{ fontSize: "80%", display: "none" }} onClick={() => editRecords()}>Edit Records</button>
                                    <button id="saveButton" className="bluebutton" style={{ fontSize: "80%", display: "none" }} onClick={() => saveRecords()}>Save</button>
                                    <button id="cancelButton" className="graybutton" style={{ fontSize: "80%", display: "none" }} onClick={() => cancelRecords()}>Cancel</button>

                                </div>
                            </div>

                            <Unregistered />
                            <Registered />
                            <Invalid />
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default AppNames;