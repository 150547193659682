import React, { useState } from "react";
import AppHeader from "../header/appheader";
import UsernameImg from "../../../../1.resources/3.images/images/username.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { populateName } from "../../../../1.resources/2.js/1.mainpages/app/populateUnregistered";
import SampleImg from "../../../../1.resources/3.images/images/sample.png"
import Unregistered from "./partials/unregistered";
import { info } from "../../../../1.resources/2.js/1.mainpages/app/app";
import Registered from "./partials/registered";
import { search } from "../../../../1.resources/2.js/1.mainpages/app/search";
import { cancelRecords, editRecords, saveRecords } from "../../../../1.resources/2.js/1.mainpages/app/populateRegistered";
import { mydomains } from "../../../../1.resources/2.js/1.mainpages/app/mydomains";
import { mobileAppMyDomains } from "../../../../1.resources/2.js/2.mobile/appMyDomains";
class MyDomains extends React.Component {
    componentDidMount() {
        info();
        mydomains();
        mobileAppMyDomains();
    }

    render() {

        return (
            <div>
                <AppHeader />

                <div id="mydomainsDiv" style={{ marginTop: "0%", marginLeft: "15%", marginRight: "15%" }}>
                    <p id="mydomainsTitle" style={{ fontSize: "120%", fontWeight: "bold", color: "#000" }}>My Domains <span id="count"></span></p>
                    <p id="mydomainsSubtitle" style={{ fontSize: "80%", fontWeight: "bold", color: "#606060" }}>Address: <span id="myaddress" style={{ color: "#7e1f80" }}></span></p>

                    <div id="mydomains" style={{ paddingBottom: "5%" }}>

                    </div>

                </div>
            </div>

        );
    }
}

export default MyDomains;