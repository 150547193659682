import React from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";

let currentaddress = "";

window.addEventListener('load', async function () {
    window.ethereum.on('accountsChanged', async (accounts) => {
        /* let walletType = localStorage.getItem("WalletType");
        if (walletType == "0") {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('WalletType', '0');
            localStorage.setItem('currentAddress', await getcurrentaddress());
            window.location.reload();
        } */
    });

    window.ethereum.on('chainChanged', (chain) => {
        sessionStorage.clear();
        window.location.reload();
    }
    );

});

export async function checkConnection() {
    let address = await getcurrentaddress();
    if (address != "null") {
        let url = window.location.href;
        // console.log(url)
        let location = url.substring(url.indexOf("?") + 1);
        // console.log(location);
        sessionStorage.clear();
        window.location = location;

    }
}

export async function getcurrentaddress() {
    if (localStorage.getItem("currentAddress") != null) {
        return localStorage.getItem("currentAddress");
    } else {
        let walletType = localStorage.getItem("WalletType");
        if (walletType == "0") {
            let add = await window.ethereum.request({ method: 'eth_accounts' });
            localStorage.setItem("currentAddress", add[0]);
            return add[0];
        } else if (walletType == "1") {
            let provider = await connectWalletConnect();
            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();
            localStorage.setItem("currentAddress", accounts[0]);
            return accounts[0];
        } else {
            // if (window.location.pathname != "/connectwallet") {
            //     window.location = "/connectwallet";
            // }
            return "null";
        }
    }
}

export async function getsafeaddress() {
    let walletType = localStorage.getItem("WalletType");
    if (walletType == "0") {
        let add = await window.ethereum.request({ method: 'eth_accounts' });
        localStorage.setItem("currentAddress", add[0]);
        return (await window.ethereum.request({ method: 'eth_accounts' }))[0];
    } else if (walletType == "1") {
        let provider = await connectWalletConnect();
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        localStorage.setItem("currentAddress", accounts[0]);
        return ((await web3.eth.getAccounts())[0]);
    } else {
        // if (window.location.pathname != "/connectwallet") {
        //     window.location = "/connectwallet";
        // }
        return "null";
    }
}

export async function getNetwork() {
    const provider = await getprovider();
    var web3 = new Web3(provider);
    let network = await web3.eth.net.getId();
    return network;
}


export async function getprovider() {
    let walletType = localStorage.getItem("WalletType");
    if (walletType == "0") {
        // console.log(walletType)
        sessionStorage.setItem("walletConnected", "true");
        return window.ethereum;
    } else if (walletType == "1") {
        let provider = await connectWalletConnect();
        sessionStorage.setItem("walletConnected", "true");
        return provider;
    } else {
        let provider;
        let tempProvider = sessionStorage.getItem("providerUrl");
        if (tempProvider != null) {
            provider = tempProvider;
        } else {
            let providerArray = JSON.parse(process.env.REACT_APP_CHAINSTACK_ARRAY).links;
            const random = Math.floor(Math.random() * providerArray.length);
            provider = providerArray[random];
            sessionStorage.setItem("providerUrl", provider);
            sessionStorage.setItem("walletConnected", "false");
        }
        //console.log(provider);
        return provider;

    }
}

export async function connectMetamask() {
    if (window.ethereum) {
        try {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            // console.log(accounts[0]);
            localStorage.setItem('WalletType', '0');
            window.location.reload();
        } catch (err) {
        }
    } else {
    }
}

export async function walletConnect() {
    await connectWalletConnect();
    window.location.reload();
}

export async function connectWalletConnect() {
    // console.log("working");
    //  Create WalletConnect Provider
    let infuraId;
    if (localStorage.getItem("infuraId") != null) {
        infuraId = localStorage.getItem("infuraId");
    } else {
        let infuraArray = JSON.parse(process.env.REACT_APP_CHAINSTACK_ETHEREUM_MAINNET_FULL_ARRAY).links;
        const random = Math.floor(Math.random() * infuraArray.length);
        infuraId = infuraArray[random];
        localStorage.setItem("infuraId", infuraId);
    }
    const provider = new WalletConnectProvider({
        rpc: {
            1: (JSON.parse(process.env.REACT_APP_CHAINSTACK_ETHEREUM_MAINNET_FULL_ARRAY).links)[0],
            137: (JSON.parse(process.env.REACT_APP_CHAINSTACK_POLYGON_MAINNET_FULL_ARRAY).links)[0]
            // ...
        }
    });

    //  Enable session (triggers QR Code modal)
    await provider.enable();
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    // console.log(accounts[0]);
    localStorage.setItem('WalletType', '1');

    provider.on("connect", () => {
        console.log("connect");
        //window.location.reload();
    });

    provider.on("accountsChanged", (accounts) => {
        // console.log(accounts);
    });

    provider.on("disconnect", () => {
        // console.log("disconnected")
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();

    });
    return provider;
}

export async function disconnectWallet() {
    let walletType = localStorage.getItem("WalletType");
    if (walletType == "1") {
        let provider = await getprovider();
        await provider.disconnect();
        localStorage.clear();
        sessionStorage.clear();
        /* if (window.location.pathname.includes("/app")) {
            window.location = "/connectwallet"
        } else {
            window.location.reload();
        } */
    } else {
        localStorage.clear();
        sessionStorage.clear();
        /* if (window.location.pathname.includes("/app")) {
            window.location = "/connectwallet"
        } else {
            window.location.reload();
        } */
    }
    window.location.reload();
}

export function shortenaddress(xstr) {
    const myArr = xstr.toString().substring(0, 4);
    const myArrlast = xstr.toString().substring(38, 42);
    const final = myArr + "..." + myArrlast
    return final
}