import { getcurrentaddress, getNetwork, shortenaddress } from "../../0.global/1.loginsystem/connectweb3";
import { CloudContracts, Contracts } from "./initializeContracts";
import ReactDom from "react-dom";
import Logos from "../../../3.images/logos.json";

let keysAddresses = ["addresses-ETH", "addresses-BTC", "addresses-LTC", "addresses-DOGE"]
let keysTxt = ["avatar", "email", "url", "description", "com.discord", "com.github", "com.twitter", "com.telegram"]


export async function populateRegistered(tokenID) {

    document.getElementById("RegisteredDiv").style.display = "block";
    document.getElementById("detailsP").style.display = "block";


    let expiration = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsRegistryContract.methods.getExpiration(tokenID).call();
    document.getElementById("expiration").innerHTML = new Date(parseFloat(expiration) * 1000).toString().substring(4, 15)
    let owner = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsRegistryContract.methods.getOwnerOf(tokenID).call();
    document.getElementById("owner").innerHTML = shortenaddress(owner);
    document.getElementById("tokenID").innerHTML = "#" + tokenID;

    document.getElementById("tokenImage").src = process.env.REACT_APP_METADATA_URL + "/metadata/images/tokenid=" + tokenID;

    populateAddresses(tokenID, owner);
    populateTxt(tokenID);

    if (owner.toLowerCase() == (await getcurrentaddress()).toLowerCase()) {
        document.getElementById("editButton").style.display = "block";
        document.getElementById("transferModal").style.display = "block";
    }

    let name = document.getElementById("name").innerHTML;
    console.log(name);
    if (isASCII(name) == false) {
        document.getElementById("errorAscii2").style.display = "flex";
    }


}

async function populateAddresses(tokenID, owner) {
    let ethAddress = await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.getTxtRecords(tokenID, "addresses-ETH").call();
    if (ethAddress == "") {
        ethAddress = owner;
    }

    document.getElementById("addresses-ETH").innerHTML = (ethAddress);
    document.getElementById("addresses-ETH").style.color = "#000";

    let addressesPromise = []
    for (let i = 1; i < keysAddresses.length; i++) {
        addressesPromise.push((await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.getTxtRecords(tokenID, keysAddresses[i]).call());
    }
    let records = await Promise.all(addressesPromise);
    console.log(records);

    for (let i = 1; i < records.length; i++) {
        if (records[i] != "") {
            document.getElementById(keysAddresses[i]).innerHTML = records[i];
        } else {
            document.getElementById(keysAddresses[i]).innerHTML = "not-set";
        }
    }

}

async function populateTxt(tokenID) {

    let txtPromise = []
    for (let i = 0; i < keysTxt.length; i++) {
        txtPromise.push((await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.getTxtRecords(tokenID, keysTxt[i]).call());
    }
    let records = await Promise.all(txtPromise);
    console.log(records);

    //console.log(await (await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full")).pnsResolverContract.methods.getTxtRecords(7, "avatar").call())
    for (let i = 0; i < records.length; i++) {
        if (records[i] != "") {
            document.getElementById(keysTxt[i]).innerHTML = records[i];
            document.getElementById(keysTxt[i]).style.color = "#000"
        } else {
            document.getElementById(keysTxt[i]).innerHTML = "not-set";
        }
    }

}

export async function editRecords() {

    document.getElementById("editButton").style.display = "none";
    document.getElementById("saveButton").style.display = "block";
    document.getElementById("cancelButton").style.display = "block";

    let network = await getNetwork();
    let appNetwork = process.env.REACT_APP_NETWORK;
    if (appNetwork == "main") {
        if (network != 137) {
            document.getElementById("networkError").style.display = "flex";
        } else {
            document.getElementById("networkError").style.display = "none";
        }
    } else if (appNetwork == "test") {
        if (network != 80001) {
            document.getElementById("networkError").style.display = "flex";
            document.getElementById("networkErrorP").innerHTML = "Switch network to Polygon (Mumbai)";
        } else {
            document.getElementById("networkError").style.display = "none";
        }
    }




    for (let i = 0; i < keysAddresses.length; i++) {
        document.getElementById(keysAddresses[i]).style.display = "none";
        document.getElementById(keysAddresses[i] + "-input").style.display = "block";
        let value = document.getElementById(keysAddresses[i]).innerHTML;
        if (value != "not-set") {
            document.getElementById(keysAddresses[i] + "-input").value = value;
        }
    }

    for (let i = 0; i < keysTxt.length; i++) {
        document.getElementById(keysTxt[i]).style.display = "none";
        document.getElementById(keysTxt[i] + "-input").style.display = "block";
        let value = document.getElementById(keysTxt[i]).innerHTML;
        if (value != "not-set") {
            document.getElementById(keysTxt[i] + "-input").value = value;
        }
    }
}

export async function saveRecords() {
    let network = await getNetwork();
    let appNetwork = process.env.REACT_APP_NETWORK;
    if (appNetwork == "main") {
        if (network == 137) {
            save()
        }
    } else if (appNetwork == "test") {
        if (network == 80001) {
            save()
        }
    }

    async function save() {
        let labels = []
        let records = []
        for (let i = 0; i < keysAddresses.length; i++) {
            labels.push(keysAddresses[i]);
            records.push(document.getElementById(keysAddresses[i] + "-input").value);
        }

        for (let i = 0; i < keysTxt.length; i++) {
            labels.push(keysTxt[i]);
            records.push(document.getElementById(keysTxt[i] + "-input").value);
        }

        let tokenID = document.getElementById("tokenID").innerHTML;
        console.log(tokenID.substring(1))
        let currentAddress = await getcurrentaddress();
        await (await Contracts(appNetwork)).pnsResolverContract.methods.setTxtRecords(labels, records, tokenID.substring(1)).send({ from: currentAddress });
    }
}

export async function cancelRecords() {
    document.getElementById("editButton").style.display = "block";
    document.getElementById("saveButton").style.display = "none";
    document.getElementById("cancelButton").style.display = "none";

    for (let i = 0; i < keysAddresses.length; i++) {
        document.getElementById(keysAddresses[i]).style.display = "block";
        document.getElementById(keysAddresses[i] + "-input").style.display = "none";
    }

    for (let i = 0; i < keysTxt.length; i++) {
        document.getElementById(keysTxt[i]).style.display = "block";
        document.getElementById(keysTxt[i] + "-input").style.display = "none";
    }
}

export function isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
}