import React, { useState } from "react";

import LogoImg from "../../../../1.resources/3.images/logo/pns2.png"
import LogoImg2 from "../../../../1.resources/3.images/logo/pns3.png"
import EmptyUser from "../../../../1.resources/3.images/images/empty.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { disconnectWallet } from "../../../../1.resources/2.js/0.global/1.loginsystem/connectweb3";
import { CloudContracts } from "../../../../1.resources/2.js/1.mainpages/app/initializeContracts";

class IndexHero extends React.Component {

    componentDidMount() {

    }





    render() {


        return (
            <div id="heroDiv" style={{ padding: "2.5%" }}>
                <div id="heroInnerDiv" style={{ padding: "20%", paddingTop: "5%", paddingBottom: "0%" }}>
                    <center>
                        <p id="heroTitleP" style={{ fontSize: "350%", fontWeight: "1000", color: "#fff" }}>Decentralised naming for wallets, websites, & more.</p>
                        <p id="heroSubtitleP" style={{ fontSize: "100%", fontWeight: "bold", color: "#fff", marginTop: "5%" }}>Built on Polygon with 🤍</p>
                        <div id="iconsDiv" className="vertcenter" style={{ marginTop: "5%" }}>
                            <a href="/app" style={{ marginRight: "1%" }}>
                                <button className="bluebutton" style={{ fontSize: "90%", whiteSpace: "nowrap" }}>Go to App</button>
                            </a>
                            <a href="https://docs.pol.domains/" target="_blank" style={{ marginLeft: "1%" }}>
                                <button className="graybutton" style={{ fontSize: "90%", whiteSpace: "nowrap" }}>Read docs</button>
                            </a>
                        </div>
                    </center>
                </div>
            </div >

        );
    }
}

export default IndexHero;